import React from 'react'
import { useLocation } from 'react-router'
import { Link as RouterLink } from 'react-router-dom'
import { useMe } from 'hooks'
import { routes } from '@market/routes'
import { useAccountContext } from '@market/hooks/useCurrentAccount'
import { MenuItem } from '@mui/material'
import { MenuLink } from 'components'
import {
  NavDrawer as AppNavDrawer,
  NavDrawerProps,
  NavMenuItem,
} from 'compositions/NavDrawer'
import {
  Settings as SettingsIcon,
  Students as StudentsIcon,
  ManageAccount as ManageAccountIcon,
} from 'icons'

export const NavigationMenuItems: React.FC<{ me: ReturnType<typeof useMe> }> = () => {
  const { hasRole, hasLevel, hasMarketAccess } = useMe()
  const { subscriptionsEnabled } = useAccountContext()

  if (!hasMarketAccess) return null

  return <>
    { routes.filter((route) => route.parent === undefined).map((route) => {
      if (route.internal && route.role && !hasRole(route.role)) return null
      if (route.internal && route.level && !hasLevel(route.level)) return null
      return <NavMenuItem key={route.path} route={route} routes={routes.filter((r) => r.parent === route.name)} context={{ subscriptionsEnabled }} />
    })}
  </>
}

export const AccountMenuItems: React.FC<{ me: ReturnType<typeof useMe>, onClick?: React.MouseEventHandler }> = ({ onClick }) => {
  const { hasMarketAccess } = useMe()
  const location = useLocation()

  if (!hasMarketAccess) return null

  return <>
    <MenuItem>
      <MenuLink component={RouterLink} to="/profile" color={location.pathname === "/profile" ? "primary" : "text.primary"} onClick={onClick} onTouchEnd={onClick}>
        <ManageAccountIcon sx={{ marginRight: 1 }} />
        My Profile
      </MenuLink>
    </MenuItem>

    <MenuItem>
      <MenuLink component={RouterLink} to={'/account/family'} sx={{ fontWeight: 'bold' }} color={location.pathname === "/account/family" ? "primary" : "text.primary"} onClick={onClick} onTouchEnd={onClick}>
        <StudentsIcon sx={{ marginRight: 1 }} />
        My Family
      </MenuLink>
    </MenuItem>

    <MenuItem onClick={onClick}>
      <MenuLink component={RouterLink} to={'/account/settings'} sx={{ fontWeight: 'bold' }} color={location.pathname === "/account/settings" ? "primary" : "text.primary"} onClick={onClick} onTouchEnd={onClick}>
        <SettingsIcon sx={{ marginRight: 1 }} />
        Settings
      </MenuLink>
    </MenuItem>
  </>
}

export const NavDrawer: React.FC<Omit<NavDrawerProps, "AccountMenuItems" | "NavigationMenuItems">> = (props) => {
  const { account } = useAccountContext()

  return <AppNavDrawer {...props} appName="market" NavigationMenuItems={NavigationMenuItems} AccountMenuItems={AccountMenuItems} currentAccount={account} />
}

export default NavDrawer