import type { CheckoutCartsInput, CheckoutCartsPayload } from '@market/graphql/schema/graphql'
import { accountCartsQuery } from '@market/graphql/queries'
import { checkoutCartsMutation } from '@market/graphql/mutations'
import { MutationTuple, useMarketMutation } from './useMarketMutation'

export type CheckoutCartsMutationData = { checkoutCarts: CheckoutCartsPayload }

export const useCheckoutCarts: (variables?: CheckoutCartsInput) => MutationTuple<CheckoutCartsMutationData, CheckoutCartsInput> = (variables)  => {
  const [ checkoutCartsFn, { data, ...result } ] = useMarketMutation<CheckoutCartsMutationData, CheckoutCartsInput>(
    checkoutCartsMutation,
    {
      variables,
      // notifyOnNetworkStatusChange: true,
      refetchQueries: [accountCartsQuery],
    }
  )

  return [ checkoutCartsFn, { ...result, data: { checkoutCarts: { orders: [] }, ...data } } ]
}

export default useCheckoutCarts
