import React from 'react'
import { Image } from 'mui-image'
import {
  Box,
  Chip,
  Button,
  IconButton,
  Text,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Currency,
} from 'components'
import { DialogProps } from 'components/Dialog/Dialog'
import {
  ArrowBack,
  Close as CloseIcon,
} from 'icons'
import { useViewport } from 'hooks'
import { Product } from '@market/graphql/schema/graphql'

export const ProductModal: React.FC<React.PropsWithChildren<DialogProps & {
  product: Product,
  onClose: () => void,
  // open?: boolean
}>> = ({ children, product, open, onClose }) => {
  const { isMedium } = useViewport()

  return <Dialog open={!!open} fullScreen={!isMedium} onClose={onClose} maxWidth="sm" fullWidth>
    <DialogTitle display="flex" flexWrap="nowrap" gap={2} sx={{ alignItems: { md: 'center' } }}>
      { !isMedium && <Box>
        <Button variant="text" onClick={onClose}><ArrowBack /></Button>
      </Box> }

      <Box>
        <Text variant="h6">{ product.name }</Text>
      </Box>

      { isMedium && <Box ml="auto">
        <IconButton onClick={onClose}><CloseIcon /></IconButton>
      </Box> }
    </DialogTitle>

    <DialogContent sx={{ padding: 0 }}>
      <Box px={2} pb={2}>
        <Text variant="subtitle1">{ product.description }</Text>
        <Box display="flex" flexWrap="wrap" gap={1} mt={1}>
          { product.tags.filter((tag) => tag.type === 'dietary').map((tag) => <Chip key={tag.id} label={tag.name} size="small" /> )}
        </Box>
      </Box>

      <Box position="relative">
        <Image
          src={product.featuredImage}
          duration={0}
          width="100%"
          height="360px"
        />

        <Box sx={(theme) => ({ position: 'absolute', bottom: 0, right: 0, px: 2, py: 1, backgroundColor: theme.palette.background.default, borderTopLeftRadius: '10px' })}>
          <Text><Currency value={product.price / 100} /></Text>
        </Box>
      </Box>

      <Box py={4} px={2}>
        { children }
      </Box>
    </DialogContent>

    <DialogActions>
      <Button onClick={onClose}>Close</Button>
    </DialogActions>
  </Dialog>
}

export default ProductModal
