import React from 'react'
import { styled } from '@mui/material'
import { Box, Button, Page, Text } from 'components'
import { UseSubscriptionWizard } from '@market/hooks/useSubscriptionWizard'
import { TasteProfile } from '@market/compositions/TasteProfile'

const StepTasteProfile = styled(TasteProfile)(({ theme }) => ({
  '& .OrdoCardGrid-wrapper': {
    [theme.breakpoints.up('md')]: {
      width: '50%',
      minWidth: '50%',
    },
    [theme.breakpoints.up('lg')]: {
      width: '33%',
      minWidth: '33%',
    },
    [theme.breakpoints.up('xl')]: {
      width: theme.spacing(theme.sizes.cardWidth),
      minWidth: theme.spacing(theme.sizes.cardWidth),
    },
  }
}))

export const TasteProfileStep: React.FC<{ wizard: UseSubscriptionWizard }> = ({ wizard }) => {

  return <Page fullWidth unpadded noMargins>
    <Box>
      <Text variant="h4">{ wizard.activeStep.name }</Text>
    </Box>
    <Box height="100%" overflow="auto" flexGrow={1} pb={4}>
      <StepTasteProfile accountUser={wizard.accountUser} />
    </Box>
    <Box p={2}>
      <Button disabled={!wizard.activeStep.completed(wizard.accountUser)} onClick={() => wizard.navigateToNext()}>Done!</Button>
    </Box>
  </Page>
}

export default TasteProfileStep
