import { gql } from '@market/graphql/schema/gql'

export const removeCartItemMutation = gql(`
  mutation removeCartItemMutation($input: RemoveCartItemInput! ) {
    removeCartItem(input: $input) {
      cart {
        ...CartFields
      }
      errors {
        code
        message
        path
      }
    }
  }
`)

export default removeCartItemMutation
