import { useMemo } from 'react'
import { DateTime } from 'luxon'
import { QueryHookOptions } from '@apollo/client'
import { accountOrdersQuery } from '@market/graphql/queries'
import type { AccountOrdersPayload, AccountOrdersQueryQueryVariables  } from '@market/graphql/schema/graphql'
import { useMarketQuery } from './useMarketQuery'

type AccountOrdersArgs = AccountOrdersQueryQueryVariables & QueryHookOptions

export const useAccountOrders = ({ accountId, filters, sorting, page, pageSize, pollInterval }: AccountOrdersArgs) => {
  const queryResult = useMarketQuery<{ accountOrders: AccountOrdersPayload }, AccountOrdersQueryQueryVariables>(
    accountOrdersQuery,
    {
      variables: { accountId, filters, sorting, page, pageSize },
      pollInterval,
    }
  )

  const payload = useMemo(() => ({
    ...queryResult,
    loadedAt: DateTime.now(),
    data: {
      accountOrders: [],
      ...queryResult.data,
    }
  }), [queryResult])

  return payload
}

export default useAccountOrders
