import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { Box, Button, Link, Page, Text } from 'components'
import { UseRegistrationWizard } from '@market/hooks/useRegistrationWizard'
import OrdoLogo from 'theme/ordo-logo.svg'
import { RegistrationForm } from './RegistrationForm'

export const SignupStep: React.FC<{ wizard: UseRegistrationWizard }> = ({ wizard }) => {

  return <Page fullWidth unpadded noMargins>
    <Box sx={{ width: { md: '600px' }, mx: { md: 'auto' } }}>
      { !!wizard.selectedSchool.locationId && <Box textAlign="center">
        <Box mb={2}>
          <Link component={RouterLink} to="/"><OrdoLogo height="60" /></Link>
        </Box>

        <Text variant="h3" mb={6}>We found your school!</Text>

        <Text fontWeight="normal" mb={4}>Please enter your name, email and a password to create your account.</Text>

        <RegistrationForm />

        <Text textAlign="center">Already have an account? <Link component={RouterLink} to="/login">Sign in</Link></Text>
      </Box> }

      { !wizard.selectedSchool.locationId && <Box textAlign="center">
        <Box mb={2}>
          <Link component={RouterLink} to="/"><OrdoLogo height="60" /></Link>
        </Box>

        <Text variant="h3" mb={6}>Bummer! We&apos;re not at your school yet.</Text>

        <Text fontWeight="normal" mb={4}>Want Ordo at your school? Get in touch with our team to schedule a call.</Text>

        <Box>
          <Button href="https://www.ordo.com/get-quote">Book a demo</Button>
        </Box>
      </Box> }
    </Box>
  </Page>
}

export default SignupStep
