import React from 'react'
import { AccountUserCard, CardGrid } from '@market/components'
import type { AccountUser } from '@market/graphql/schema/graphql'
import { CreateAccountUserCard } from './CreateAccountUserCard'

export const FamilyGrid: React.FC<{ family: AccountUser[] }> = ({ family }) => {
  return <CardGrid>
    { family.map((acctUser) =>
      <AccountUserCard key={acctUser.id} accountUser={acctUser} />
    ) }

    <CreateAccountUserCard />
  </CardGrid>
}

export default FamilyGrid
