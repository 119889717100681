import React, { useEffect } from 'react'
import { Route, Routes, useParams } from 'react-router-dom'
import { withSentryReactRouterV6Routing } from '@sentry/react'
import { useAccountOnboardingWizard } from '@market/hooks/useAccountOnboardingWizard'
import { ContextProvider } from './ContextProvider'
import { WizardStep } from './WizardStep'
import { Step } from '@market/hooks/useAccountOnboardingWizard'

const SentryRoutes = withSentryReactRouterV6Routing(Routes)

export const WizardRoute: React.FC = () => {
  const { step } = useParams()
  const wizard = useAccountOnboardingWizard({ step: (step as Step) || 'profile' })

  useEffect(() => {
    if (!wizard?.activeStep?.available(wizard.account)) {
      wizard.navigateToPrev()
    }
  }, [wizard])

  return <ContextProvider {...wizard}>
    <WizardStep />
  </ContextProvider>
}

export const AccountOnboardingWizard: React.FC = () => {
  return <SentryRoutes>
    <Route path="/" element={<WizardRoute />} />
    <Route path="/:step" element={<WizardRoute />} />
  </SentryRoutes>

}

export default AccountOnboardingWizard
