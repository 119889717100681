import React from 'react'
import { Wrapper } from 'compositions/Application'
import Routes from '@market/routes'
import { theme } from '@market/theme'
import { ContextProvider } from './Context'
import { Layout } from './Layout'

export const Application: React.FC = () =>
  <Wrapper theme={theme} apolloClient="market">
    <ContextProvider>
      <Layout>
        <Routes />
      </Layout>
    </ContextProvider>
  </Wrapper>

export default Application
