import React from 'react'
import { Box, Text } from 'components'
import { MenuProduct } from '@market/graphql/schema/graphql'
import { CardGrid, MenuProductCard } from '@market/components'

export const MenuSection: React.FC<{ date: string, section: string, menuProducts: MenuProduct[], openModal: (menuProduct: MenuProduct, date: string) => void }> = ({ date, section, menuProducts, openModal }) => {
  if (menuProducts.length === 0) return null

  return <Box mb={4}>
    <Box mb={3}>
      <Text variant="h5" px={{ xs: 1, md: 0 }}>{ section }</Text>
    </Box>

    <CardGrid>
      { menuProducts.map((menuProduct) =>
        <MenuProductCard key={menuProduct.id} date={date} menuProduct={menuProduct} openModal={openModal} />
      ) }
    </CardGrid>
  </Box>
}

export default MenuSection
