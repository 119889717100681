import React, { useCallback } from 'react'
import { CardGrid } from '@market/components/CardGrid'
import { AccountUser, Product, ProductReactionEnum } from '@market/graphql/schema/graphql'
import { useAddProductReaction, useRemoveProductReaction } from '@market/hooks'
import { ProductCard } from './ProductCard'

export const ProductGrid: React.FC<{ accountUser: AccountUser, products: Product[] }> = ({ accountUser, products }) => {
  const [addProductReactionMutation] = useAddProductReaction()
  const [removeProductReactionMutation] = useRemoveProductReaction()

  const addProductReaction = useCallback((productId: Product['id'], reaction: ProductReactionEnum) => {
    addProductReactionMutation({ variables: { productId, reaction, userId: accountUser.user.id } })
  }, [accountUser, addProductReactionMutation])

  const removeProductReaction = useCallback((productId: Product['id']) => {
    removeProductReactionMutation({ variables: { productId, userId: accountUser.user.id } })
  }, [accountUser, removeProductReactionMutation])

  return <CardGrid>
    { products.map((product) =>
      <ProductCard
        key={product.id}
        accountUser={accountUser}
        product={product}
        addProductReaction={addProductReaction}
        removeProductReaction={removeProductReaction}
      />
    ) }
  </CardGrid>
}

export default ProductGrid
