import React, { useCallback, useMemo } from 'react'
import { Image } from 'mui-image'
import { CircularProgress } from '@mui/material'
import {
  Box,
  IconButton,
  Currency,
  Text,
} from 'components'
import { Delete as DeleteIcon } from 'icons'
import { CartItem } from '@market/graphql/schema/graphql'
import { useUpdateCartItem, useRemoveCartItem } from '@market/hooks'
import { QuantityButtonGroup } from '@market/components'

export type UserCartItemProps = {
  item: CartItem
}

export const UserCartItem: React.FC<UserCartItemProps> = ({ item }) => {
  const [updateCartItem, { loading: updateLoading }] = useUpdateCartItem()
  const [removeCartItem, { loading: removeLoading }] = useRemoveCartItem()
  const loading = useMemo(() => updateLoading || removeLoading, [updateLoading, removeLoading])

  const handleClickAdd = useCallback(() => {
    updateCartItem({ variables: { cartId: item.cartId, itemId: item.id, quantity: item.quantity + 1 } })
  }, [updateCartItem, item])

  const handleClickRemove = useCallback(() => {
    removeCartItem({ variables: { cartId: item.cartId, itemId: item.id } })
  }, [removeCartItem, item])

  const handleClickSubtract = useCallback(() => {
    if (item.quantity === 1) {
      removeCartItem({ variables: { cartId: item.cartId, itemId: item.id } })
    } else {
      updateCartItem({ variables: { cartId: item.cartId, itemId: item.id, quantity: item.quantity - 1 } })
    }
  }, [updateCartItem, removeCartItem, item])

  const handleChangeQuantity = useCallback((quantity: number) => {
    if (quantity === 0) {
      removeCartItem({ variables: { cartId: item.cartId, itemId: item.id } })
    } else {
      updateCartItem({ variables: { cartId: item.cartId, itemId: item.id, quantity: quantity } })
    }
  }, [updateCartItem, removeCartItem, item])

  return <Box display="flex" flexWrap="nowrap" alignItems="stretch" gap={1} sx={(theme) => ({
    borderTopWidth: { xs: '1px', md: 0 },
    borderTopStyle: 'solid',
    borderTopColor: theme.palette.divider,
    pl: { xs: 0, md: 1 },
    mb: { xs: 0, md: 1 },
  })}>
    <Box
      flexShrink={0}
      width={{ xs: '120px', md: '100px' }}
      height={{ xs: '120px', md: '100px' }}
    >
      <Image
        src={item.product.featuredImage}
        duration={0}
        width="100%"
        height="100%"
        sx={{ borderRadius: { md: '10px' } }}
      />
    </Box>

    <Box flexGrow={1} display="flex" flexDirection="column" justifyContent="space-between" pb={1}>
      <Box pb={1}>
        <Text>{ item.product.name }</Text>
        { !item.validation.valid && <Text variant="caption" color="error"><IconButton onClick={handleClickRemove}><DeleteIcon /></IconButton> { item.validation.errors[0].message }</Text> }
      </Box>

      <Box display="flex" flexWrap="nowrap" alignItems="center">
        <Box>
          { item.validation.valid && <QuantityButtonGroup
            onIncrease={handleClickAdd}
            onDecrease={handleClickSubtract}
            onChange={handleChangeQuantity}
            disabled={loading}
            value={item.quantity.toString()}
          /> }
        </Box>

        <Box ml="auto" pr={1}>
          <Text>{ loading ? <CircularProgress size={20} /> : <Currency value={item.subtotalCents / 100} currency={item.subtotalCurrency} />}</Text>
        </Box>
      </Box>
    </Box>
  </Box>
}

export default UserCartItem
