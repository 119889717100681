import type { UpdateCartItemInput, UpdateCartItemPayload } from '@market/graphql/schema/graphql'
import { updateCartItemMutation } from '@market/graphql/mutations'
import { MutationTuple, useMarketMutation } from './useMarketMutation'

export type UpdateCartItemFn = ReturnType<typeof useUpdateCartItem>[0]
export type UpdateCartItemData = { updateCartItem: UpdateCartItemPayload }

export const useUpdateCartItem: (variables?: UpdateCartItemInput) => MutationTuple<UpdateCartItemData, UpdateCartItemInput> = (variables)  => {
  const [ updateCartItemFn, { data, ...result } ] = useMarketMutation<UpdateCartItemData, UpdateCartItemInput>(
    updateCartItemMutation,
    {
      variables,
      // notifyOnNetworkStatusChange: true,
    }
  )

  return [ updateCartItemFn, { ...result, data } ]
}

export default useUpdateCartItem
