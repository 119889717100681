import React, { useCallback, useMemo } from 'react'
import { StripePaymentMethod } from '@market/graphql/schema/graphql'
import { useMe } from 'hooks'
import { Box, Button, Text } from 'components'
import { useArchivePaymentMethod, useSetDefaultPaymentMethod } from '@market/hooks'
import { PaymentMethodIcon } from '@market/components'
import { Delete as DeleteIcon } from 'icons'

export const PaymentMethodList: React.FC<{ paymentMethods: StripePaymentMethod[] }> = ({ paymentMethods }) => {
  const { me } = useMe()
  const [ archivePaymentMethod ] = useArchivePaymentMethod()
  const [ setDefaultPaymentMethod ] = useSetDefaultPaymentMethod()

  const activePaymentMethods = useMemo(() => {
    return paymentMethods.filter((pm) => !pm.isArchived)
  }, [paymentMethods])

  const onClickArchive = useCallback((paymentMethodId: string) => {
    archivePaymentMethod({ variables: { accountId: me?.account?.id, paymentMethodId } })
  }, [me, archivePaymentMethod])

  const onClickDefault = useCallback((paymentMethodId: string) => {
    setDefaultPaymentMethod({ variables: { accountId: me?.account?.id, paymentMethodId } })
  }, [me, setDefaultPaymentMethod])

  return <Box display="flex" flexDirection="column">
    { activePaymentMethods.map((paymentMethod) => <Box key={paymentMethod.id} display="flex">
      <Box><PaymentMethodIcon width="50px" /></Box>

      <Box display="flex" flexDirection="column" flexGrow={1}>
        <Text>{ paymentMethod.displayBrandName }</Text>
        <Text>{ paymentMethod.last4 }</Text>
      </Box>

      <Box>
        <Button
          fullWidth={false}
          disabled={paymentMethod.default}
          variant={paymentMethod.default ? "contained" : "outlined"}
          onClick={() => onClickDefault(paymentMethod.id)}
          sx={{ mr: 2 }}
        >Default</Button>
        <Button
          fullWidth={false}
          disabled={paymentMethod.isArchived}
          onClick={() => onClickArchive(paymentMethod.id)}
          color="error"
        >
          <DeleteIcon />
        </Button>
      </Box>
    </Box>) }
  </Box>
}

export default PaymentMethodList
