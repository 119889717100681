import { useCallback, useEffect, useReducer } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { AccountUser } from '@market/graphql/schema/graphql'

export type AccountUserModalState = {
  open?: boolean
  accountUser: AccountUser
  loading?: boolean
  errors?: string[]
}

export type AccountUserModalAction =
  | { type: 'OPEN_MODAL' }
  | { type: 'CLOSE_MODAL' }
  | { type: 'SET_LOADING', payload: boolean }
  | { type: 'SET_ERRORS', payload: string[] }
  | { type: 'CLEAR_ERRORS' }

const defaultState: AccountUserModalState = {
  open: false,
  accountUser: undefined,
  loading: false,
}

const accountUserModalReducer = (state: AccountUserModalState, action: AccountUserModalAction) => {
  switch(action.type) {
    case 'OPEN_MODAL':
      return {
        ...state,
        open: true,
      }

    case 'CLOSE_MODAL':
      return {
        ...state,
        open: false,
        loading: undefined,
        errors: undefined,
      }

    case 'SET_LOADING':
      return {
        ...state,
        loading: action.payload,
      }

    case 'SET_ERRORS':
      return {
        ...state,
        errors: action.payload,
      }

    case 'CLEAR_ERRORS':
      return {
        ...state,
        errors: undefined,
      }

    default:
      return state
  }
}

export const useAccountUserModal = (initialState: AccountUserModalState) => {
  const location = useLocation()
  const navigateTo = useNavigate()
  const [ state, dispatch ] = useReducer(accountUserModalReducer, { ...defaultState, ...initialState })
  const { accountUser } = state

  const openModal = useCallback(() => dispatch({ type: 'OPEN_MODAL' }), [dispatch])
  const closeModal = useCallback(() => dispatch({ type: 'CLOSE_MODAL' }), [dispatch])
  const setLoading = useCallback((loading: boolean) => dispatch({ type: 'SET_LOADING', payload: loading }), [dispatch])
  const setErrors = useCallback((errors: string[]) => dispatch({ type: 'SET_ERRORS', payload: errors }), [dispatch])
  const clearErrors = useCallback(() => dispatch({ type: 'CLEAR_ERRORS' }), [dispatch])

  useEffect(() => {
    if (location.state?.open && location.state?.accountUserId === accountUser.id) {
      openModal()
    } else {
      closeModal()
    }
  }, [location, accountUser, openModal, closeModal])

  const navOpenModal = useCallback(() => {
    // TODO: `/accountUsers/12345`?
    navigateTo(location.pathname, { state: { ...location.state, open: true, accountUserId: accountUser.id } })
  }, [location, accountUser, navigateTo])

  const navCloseModal = useCallback(() => {
    closeModal()
    navigateTo(-1)
  }, [closeModal, navigateTo])

  return {
    ...state,
    openModal: navOpenModal,
    closeModal: navCloseModal,
    setLoading,
    setErrors,
    clearErrors,
  }
}

export type UseAccountUserModalReturn = ReturnType<typeof useAccountUserModal>

export default useAccountUserModal
