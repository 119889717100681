import type { CreateSubscriptionInput, CreateSubscriptionPayload } from '@market/graphql/schema/graphql'
import { createSubscriptionMutation } from '@market/graphql/mutations'
import { MutationTuple, useMarketMutation } from './useMarketMutation'

export type CreateSubscriptionMutationData = { createSubscription: CreateSubscriptionPayload }

export const useCreateSubscription: (variables?: CreateSubscriptionInput) => MutationTuple<CreateSubscriptionPayload, CreateSubscriptionInput> = (variables)  => {
  const [ createSubscriptionFn, { data, ...result } ] = useMarketMutation<CreateSubscriptionPayload, CreateSubscriptionInput>(
    createSubscriptionMutation,
    {
      variables,
      // notifyOnNetworkStatusChange: true
    }
  )

  return [ createSubscriptionFn, { ...result, data } ]
}

export default useCreateSubscription
