import { gql } from '@market/graphql/schema/gql'

export const addCartItemsMutation = gql(`
  mutation addCartItemsMutation($input: AddCartItemsInput! ) {
    addCartItems(input: $input) {
      carts {
        ...CartFields
      }
      errors {
        code
        message
        path
      }
    }
  }
`)

export default addCartItemsMutation
