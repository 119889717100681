import React from 'react'
import { EmptyState, Page } from 'components'
import { Menu as MenuIcon } from 'icons'
import { useAccountContext } from '@market/hooks/useCurrentAccount'
import { Menus as MenusPage } from '@market/compositions/Menus'

export const Menus: React.FC = () => {
  const { locations } = useAccountContext()

  return <Page fullWidth unpadded>
    { locations.length > 0 && <MenusPage locations={locations} /> }
    { locations.length === 0 && <EmptyState icon={MenuIcon} message="No items to display." /> }
  </Page>
}

export default Menus
