import { gql } from '@market/graphql/schema/gql'

export const accountCartsQuery = gql(`
  query accountCartsQuery($accountId: ID!) {
    accountCarts(accountId: $accountId) {
      ...CartFields
    }
  }
`)

export default accountCartsQuery
