import { useMemo } from 'react'
import { QueryHookOptions } from '@apollo/client'
import type { ServicedSchoolsQueryQuery, ServicedSchoolsQueryQueryVariables } from '@market/graphql/schema/graphql'
import { servicedSchoolsQuery } from '@market/graphql/queries'
import { LazyQueryResultTuple, useLazyMarketQuery } from './useLazyMarketQuery'

type ServicedSchoolsArgs = ServicedSchoolsQueryQueryVariables & QueryHookOptions

export const useServicedSchools = (args?: ServicedSchoolsArgs): LazyQueryResultTuple<ServicedSchoolsQueryQuery, ServicedSchoolsQueryQueryVariables> => {
  const [ servicedSchools, queryResult ] = useLazyMarketQuery<ServicedSchoolsQueryQuery, ServicedSchoolsQueryQueryVariables>(
    servicedSchoolsQuery,
    {
      variables: { state: args?.state, locationId: args?.locationId },
      pollInterval: args?.pollInterval,
    }
  )

  const payload = useMemo(() => ({
    ...queryResult,
    data: {
      servicedSchools: [],
      ...queryResult.data,
    }
  }), [queryResult])

  return [
    servicedSchools,
    payload,
  ]
}

export default useServicedSchools
