import React from 'react'
import { Text, TextProps } from 'components/Text'
import { Order } from '@market/graphql/schema/graphql'

export const OrderStatus: React.FC<Omit<TextProps, 'order'> & { order: Order }> = ({ order, ...props }) =>
  <Text {...props} variant="h6" sx={(theme) => {
    const statusColors = {
      "REFUNDED": theme.palette.error.main,
      "CONFIRMED": theme.palette.warning.main,
      "PAID": theme.palette.success.main,
      "CANCELLED": theme.palette.grey[400],
    }
    return ({ color: statusColors[order.computedStatus] })
  }}>{ order.displayStatus }</Text>

  export default OrderStatus