import React from 'react'
import { styled } from '@mui/material'
import { Box, BoxProps } from 'components/Box'

const StyledCardWrapper = styled(Box)(({ theme }) => ({
  width: '50%',
  minWidth: '50%',
  height: 'auto',
  aspectRatio: '1/1',
  padding: theme.spacing(1),
  [theme.breakpoints.up('sm')]: {
    width: '33%',
    minWidth: '33%',
  },
  [theme.breakpoints.up('md')]: {
    width: '25%',
    minWidth: '25%',
  },
  [theme.breakpoints.up('lg')]: {
    width: theme.spacing(theme.sizes.cardWidth),
    minWidth: theme.spacing(theme.sizes.cardWidth),
  },
  [theme.breakpoints.up('xl')]: {
    width: theme.spacing(theme.sizes.cardWidth),
    minWidth: theme.spacing(theme.sizes.cardWidth),
  },
}))

export const CardWrapper: React.FC<BoxProps> = (props) => {
  return <StyledCardWrapper {...props} className="OrdoCardGrid-wrapper" />
}

export default CardWrapper
