import { styled } from '@mui/material'
import { Chip } from 'components'

export const QuantityChip = styled(Chip)(({ size=undefined }) => ({
  borderRadius: '50%',
  width: size || '40px',
  height: size || '40px',
  fontWeight: 'medium',
  fontSize: '1rem',
  '& .MuiChip-label': {
    overflow: 'visible',
    textOverflow: 'unset',
  },
}))

export default QuantityChip
