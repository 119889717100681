import React, { useCallback, useMemo, useState } from 'react'
import {
  Box,
  Button,
  IconButton,
  Currency,
  ConfirmDialog,
  Text,
} from 'components'
import { Delete as DeleteIcon } from 'icons'
import { Cart } from '@market/graphql/schema/graphql'
import { useClearCart } from '@market/hooks'
import { UserCartDate } from './UserCartDate'
import { DateTime } from 'luxon'

export type UserCartProps = {
  cart: Cart
}

export const UserCart: React.FC<UserCartProps> = ({ cart }) => {
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)
  const [clearCart] = useClearCart()

  const handleClickClear = useCallback(() => {
    clearCart({ variables: { cartId: cart.id } })
  }, [cart, clearCart])

  const groupedItems = useMemo(() =>
    [ ...cart.cartItems ].sort((a, b) => DateTime.fromISO(a.date) >= DateTime.fromISO(b.date) ? 1 : -1).reduce((memo, cartItem) => {
      memo[cartItem.date] ||= []
      memo[cartItem.date].push(cartItem)

      return memo
    }, {}), [cart])

  if (cart.cartItems.length === 0) {
    return null
  } else {
    return <Box mb={4}>
      <ConfirmDialog
        open={confirmDialogOpen}
        onClose={() => setConfirmDialogOpen(false)}
        content={<Text>Are you sure you want to clear { cart.user.preferredName || cart.user.firstName } cart?</Text>}
        action={<Button color="error" onClick={() => {
          handleClickClear()
          setConfirmDialogOpen(false)
        }}>Clear cart</Button>}
      />

      <Box display="flex" px={2}>
        <Text variant="h6">{ cart.user.displayName }</Text>
        <IconButton
          size="small"
          sx={{ ml: 'auto' }}
          onClick={() => setConfirmDialogOpen(true)}
        ><DeleteIcon /></IconButton>
      </Box>

      <Box>
        { Object.keys(groupedItems).map((key) => <UserCartDate key={key} date={key} items={groupedItems[key]} />) }
      </Box>

      <Box display="flex" flexWrap="nowrap" alignItems="center" gap={2} mt={1} pl={2} pr={1}>
        <Text>Subtotal</Text>
        <Text ml="auto"><Currency value={cart.subtotalCents / 100} currency={cart.taxesCurrency} /></Text>
      </Box>

      <Box display="flex" flexWrap="nowrap" alignItems="center" gap={2} mt={1} pl={2} pr={1}>
        <Text>Tax</Text>
        <Text ml="auto"><Currency value={cart.taxesCents / 100} currency={cart.taxesCurrency} /></Text>
      </Box>
    </Box>
  }
}

export default UserCart
