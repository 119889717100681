import React, { useCallback, useMemo } from 'react'
import {
  Box,
  Button,
  Text,
  ButtonFilter,
} from 'components'
import {
  FiltersPopover,
  FiltersPopoverHeader,
  FiltersPopoverContent,
  FiltersPopoverFooter,
} from 'components/Filters'
import { usePopoverState, useViewport } from 'hooks'
import { School as SchoolIcon } from 'icons'
import { FilterState, FilterProps, useContext } from '@market/hooks/useFilteredAvailableMenuProducts'

export type MenuFiltersProps = Partial<FilterProps> & {
  filters: FilterState
}

export const LocationFilter: React.FC = () => {
  const { isMedium } = useViewport()
  const { filters: { filters, filterDefinitions, appliedFilters, availableLocations, setAndApplyFilters } } = useContext()
  const { open, anchorElement, handleClick, handleClose } = usePopoverState()
  const appliedFiltersCount = useMemo(() => {
    return appliedFilters.locationIds.length
  }, [appliedFilters])

  const setAndClose = useCallback((state: Partial<FilterState>) => {
    setAndApplyFilters(state)
    handleClose()
  }, [setAndApplyFilters, handleClose])

  return <Box>
    <Button onClick={handleClick} variant="outlined" fullWidth={false}>
      <SchoolIcon />
      { appliedFiltersCount === 0 && <Text ml={1}>All Campuses</Text> }
      { appliedFiltersCount > 0 && <><Text ml={1}>{ availableLocations.find((loc) => appliedFilters.locationIds.includes(loc.id))?.name }</Text> { appliedFiltersCount > 1 && <Text fontWeight="bold" ml={1}>(+{ appliedFiltersCount - 1 })</Text> }</> }
    </Button>
    <FiltersPopover
      open={open}
      anchorEl={anchorElement}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      sx={{ marginTop: { md: 1 } }}
    >
      <FiltersPopoverHeader onClose={handleClose}>
        <Text variant="h6">Campus</Text>
      </FiltersPopoverHeader>

      <FiltersPopoverContent>
        <ButtonFilter filterName="locationIds" filterDefinitions={filterDefinitions} filters={filters} setFilters={setAndClose} hideLabel deselectedVariant="text" />
      </FiltersPopoverContent>

      <FiltersPopoverFooter sx={(theme) => ({
        display: 'flex',
        gap: 1,
        [theme.breakpoints.up('xs')]: {
          flexDirection: 'column',
        },
        [theme.breakpoints.up('md')]: {
          display: 'none',
          flexDirection: 'row',
        },
      })}>
        <Box width={isMedium ? undefined : '100%'}>
          <Button fullWidth={!isMedium} variant="outlined" onClick={handleClose}>Cancel</Button>
        </Box>
      </FiltersPopoverFooter>
    </FiltersPopover>
  </Box>
}

export default LocationFilter
