import React from 'react'
import {
  Box,
  Button,
  IconButton,
  Text,
  Dialog,
  DialogTitle,
  DialogContent,
} from 'components'
import { DialogProps } from 'components/Dialog/Dialog'
import {
  ArrowBack,
  Close as CloseIcon,
} from 'icons'
import { useViewport } from 'hooks'
import { AccountUser } from '@market/graphql/schema/graphql'
import { AvatarIcon } from '../AvatarIcon'

export const AccountUserModal: React.FC<React.PropsWithChildren<DialogProps & {
  accountUser: AccountUser
  onClose: () => void
}>> = ({ children, accountUser, open, onClose }) => {
  const { user } = accountUser
  const { isMedium } = useViewport()

  return <Dialog open={!!open} fullScreen={!isMedium} onClose={onClose} maxWidth="sm" fullWidth>
    <DialogTitle display="flex" flexWrap="nowrap" gap={2} sx={{ alignItems: { md: 'center' } }}>
      { !isMedium && <Box>
        <Button variant="text" onClick={() => onClose()}><ArrowBack /></Button>
      </Box> }

      <Box display="flex" alignItems="center" gap={2}>
        <AvatarIcon name={user.preferredName || user.firstName} />
        <Text variant="h6">{ user.displayName }</Text>
      </Box>

      { isMedium && <Box ml="auto">
        <IconButton onClick={() => onClose()}><CloseIcon /></IconButton>
      </Box> }
    </DialogTitle>

    <DialogContent sx={{ padding: 0 }}>
      <Box py={2} px={2}>
        { children }
      </Box>
    </DialogContent>
  </Dialog>
}

export default AccountUserModal
