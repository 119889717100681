import { useAccountContext } from './useCurrentAccount'

export const useFamily = () => {
  const { account, locations } = useAccountContext()

  return {
    account,
    family: account?.accountUsers || [],
    locations: locations || [],
  }
}

export default useFamily
