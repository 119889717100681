import React from 'react'
import { Alert, Box, Form, FormTextField, FormSubmitButton } from 'components'
import { useRegistrationForm } from '@market/hooks/useRegistrationForm'

export const RegistrationForm = () => {
  const [form, { loading, errors }] = useRegistrationForm()

  return (
    <Form form={form}>
      <Box display="flex" gap={2}>
        <FormTextField
          id="firstName"
          name="firstName"
          label="First name"
          autoFocus={true}
        />

        <FormTextField
          id="lastName"
          name="lastName"
          label="Last name"
        />
      </Box>

      <FormTextField
        id="email"
        name="email"
        label="Email address"
        autoComplete="username"
      />

      <Box display="flex" gap={2}>
        <FormTextField
          id="password"
          name="password"
          label="Password"
          type="password"
        />

        <FormTextField
          id="passwordConfirmation"
          name="passwordConfirmation"
          label="Password confirmation"
          type="password"
        />
      </Box>

      { !loading && <>{ errors.map((error) => <Alert key={`${error.code}:${error.message}`} severity="error" sx={{ width: '100%', mb: 2 }}>
        {error.message}
      </Alert>)}</> }

      <FormSubmitButton
        loading={loading}
      >
        Get started
      </FormSubmitButton>
    </Form>
  )
}

export default RegistrationForm
