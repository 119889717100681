import { useEffect, useMemo } from 'react'
import { DateTime, Interval } from 'luxon'
import { Filter, FilterProps as BaseFilterProps, setupContext, useFilters } from 'hooks/useFilters'
import type { MenuProduct, SchoolLocation, Tag } from '@market/graphql/schema/graphql'
import { useAvailableMenuProducts } from './useAvailableMenuProducts'

export type MenuTypeMap = {
  "Menus::LunchMenu": "Lunch",
  "Menus::DinnerMenu": "Dinner",
  "Menus::BreakfastMenu": "Breakfast",
}

export const menuTypeMap: MenuTypeMap = {
  "Menus::LunchMenu": "Lunch",
  "Menus::DinnerMenu": "Dinner",
  "Menus::BreakfastMenu": "Breakfast",
}

export interface FilterState {
  timeZone: string
  startDate: DateTime
  endDate: DateTime
  filteredDate: DateTime
  locationIds: string[]
  dietaryTagIds: string[]
  temperatureTagIds: string[]
  menuTypes: string[] // (MenuTypeMap["Menus::LunchMenu"] | MenuTypeMap["Menus::DinnerMenu"] | MenuTypeMap["Menus::BreakfastMenu"])[]
  visibleProducts: boolean
}

export type FilterProps = Pick<
  BaseFilterProps<FilterState, MenuProduct>,
  | "filters"
  | "appliedFilters"
  | "filterDefinitions"
  | "setFilters"
  | "applyFilters"
  | "setAndApplyFilters"
  | "clearFilters"
  | "applicable"
  | "clearable"
> & Pick<ReturnType<typeof useAvailableMenuProducts>, "loading"> & {
  availableDietaryTags: Tag[]
  availableTemperatureTags: Tag[]
  availableMenuTypes: [string, string][]
  availableLocations: SchoolLocation[]
  availableDates: DateTime[]
}

const timeZoneFilter: Filter<FilterState, MenuProduct, 'timeZone'> = {
  name: 'timeZone',
  label: 'Time Zone',
}

const startDateFilter: Filter<FilterState, MenuProduct, 'startDate'> = {
  name: 'startDate',
  label: 'Start Date',
  match: (menuProduct, { startDate }) => {
    if (!startDate) return true
    return menuProduct.availabilityDates.some((date) => DateTime.fromISO(date) >= startDate)
  },
}

const endDateFilter: Filter<FilterState, MenuProduct, 'endDate'> = {
  name: 'endDate',
  label: 'End Date',
  match: (menuProduct, { endDate }) => {
    if (!endDate) return true
    return menuProduct.availabilityDates.some((date) => DateTime.fromISO(date) <= endDate)
  },
}

const filteredDateFilter: Filter<FilterState, MenuProduct, 'filteredDate'> = {
  name: 'filteredDate',
  label: 'Selected Date',
  match: (menuProduct, { filteredDate }) => {
    if (!filteredDate) return true
    return menuProduct.availabilityDates.some((date) => DateTime.fromISO(date).toISO() === filteredDate.toISO())
  },
}

const locationFilter: Filter<FilterState, MenuProduct, 'locationIds'> = {
  name: 'locationIds',
  label: 'Location',
  match: (menuProduct, { locationIds }) => {
    if (locationIds.length === 0) return true
    return locationIds.includes(menuProduct.menu.location.id)
  },
}

const dietaryTagFilter: Filter<FilterState, MenuProduct, 'dietaryTagIds'> = {
  name: 'dietaryTagIds',
  label: 'Dietary',
  match: (menuProduct, { dietaryTagIds }) => {
    if (dietaryTagIds.length === 0) return true
    return dietaryTagIds.every((tagId) => menuProduct.product?.tags?.some((tag) => tag.id === tagId))
  },
}

const temperatureTagFilter: Filter<FilterState, MenuProduct, 'temperatureTagIds'> = {
  name: 'temperatureTagIds',
  label: 'Temperature',
  match: (menuProduct, { temperatureTagIds }) => {
    if (temperatureTagIds.length === 0) return true
    return temperatureTagIds.some((tagId) => menuProduct.product?.tags.some((tag) => tag.id === tagId))
  },
}

const menuTypeFilter: Filter<FilterState, MenuProduct, 'menuTypes'> = {
  name: 'menuTypes',
  label: 'Mealtimes',
  match: (menuProduct, { menuTypes }) => {
    if (menuTypes.length === 0) return true
    return menuTypes.includes(menuProduct.menu.type)
  },
}

const visibleProductsFilter: Filter<FilterState, MenuProduct, 'visibleProducts'> = {
  name: 'visibleProducts',
  label: 'Visible Products',
  match: (menuProduct, { visibleProducts }) => {
    if (!visibleProducts) return true
    return menuProduct.visible === true
  },
}

export const { Context, ContextProvider, useContext } = setupContext<ReturnType<typeof useFilteredAvailableMenuProducts>>()

export const useFilteredAvailableMenuProducts = (locations: SchoolLocation[], initialState?: Partial<FilterState>) => {
  const timeZone = useMemo(() => {
    return locations[0]?.timeZone
  }, [locations])

  const startDate = useMemo(() => {
    if (initialState?.startDate) {
      return initialState?.startDate.setZone(timeZone)
    } else {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return DateTime.fromISO(DateTime.now().toISO()).setZone(timeZone).plus({ weeks: 1 }).startOf('week', { useLocaleWeeks: true })
    }
  }, [initialState, timeZone])

  const endDate = useMemo(() => {
    if (initialState?.endDate) {
      return initialState?.endDate.setZone(timeZone)
    } else {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return startDate.endOf('week', { useLocaleWeeks: true })
    }
  }, [initialState, timeZone, startDate])

  const filters = useFilters<FilterState, MenuProduct>(
    [
      timeZoneFilter,
      startDateFilter,
      endDateFilter,
      filteredDateFilter,
      locationFilter,
      dietaryTagFilter,
      temperatureTagFilter,
      menuTypeFilter,
      visibleProductsFilter,
    ],
    {
      timeZone: timeZone,
      startDate,
      endDate,
      filteredDate: undefined,
      locationIds: [...(initialState?.locationIds || [locations[0]?.id].filter((v) => v !== undefined))],
      dietaryTagIds: [...(initialState?.dietaryTagIds || [])],
      temperatureTagIds: [...(initialState?.temperatureTagIds || [])],
      menuTypes: [...(initialState?.menuTypes || [])],
      visibleProducts: true,
    },
    [],
  )

  const { filterData, setFilterOptions, setAndApplyFilters, appliedFilters } = filters

  const { data, ...rest } = useAvailableMenuProducts({
    locationId: appliedFilters.locationIds,
    startDate: appliedFilters.startDate,
    endDate: appliedFilters.endDate,
    pollInterval: 6000000,
  })

  const availableLocations = useMemo(
    () => locations.filter((v, i, a) => a.indexOf(v) === i),
    [locations]
  )

  const availableDietaryTags = useMemo(
    () =>
      data?.availableMenuProducts.flatMap((menuProduct) => menuProduct.product.tags)
      .filter((v, i, a) => a.indexOf(v) === i && v.type === 'dietary')
      .sort((a, b) => a.name.localeCompare(b.name)) || [],
    [data?.availableMenuProducts]
  )

  const availableTemperatureTags = useMemo(
    () =>
      data?.availableMenuProducts.flatMap((menuProduct) => menuProduct.product.tags)
      .filter((v, i, a) => a.indexOf(v) === i && v.type === 'temperature')
      .sort((a, b) => a.name.localeCompare(b.name)) || [],
    [data?.availableMenuProducts]
  )

  const availableMenuTypes = useMemo(
    () =>
      (data?.availableMenuProducts.map((menuProduct) => [menuProduct.menu.type, menuTypeMap[menuProduct.menu.type]])
      .filter((v, i, a) => a.indexOf(v) === i)
      .sort((a, b) => a[1].localeCompare(b[1])) || []) as [string, string][],
    [data?.availableMenuProducts]
  )

  const availableDates = useMemo(
    () =>
      data?.availableMenuProducts.flatMap((menuProduct) => menuProduct.availabilityDates)
      .filter((v, i, a) => a.indexOf(v) === i)
      .map((date) => DateTime.fromISO(date))
      .sort((a, b) => a <= b ? -1 : 1) || [],
    [data?.availableMenuProducts]
  )

  useEffect(() => {
    setFilterOptions('locationIds', availableLocations.map((loc) => ({ key: loc.displayName, value: loc.id })))
    setFilterOptions('dietaryTagIds', availableDietaryTags.map((tag) => ({ key: tag.name, value: tag.id })))
    setFilterOptions('temperatureTagIds', availableTemperatureTags.map((tag) => ({ key: tag.name, value: tag.id })))
    setFilterOptions('menuTypes', Object.keys(menuTypeMap).map((menuType) => ({ key: menuTypeMap[menuType], value: menuType, disabled: !availableMenuTypes.some((amt) => amt[0] === menuType) })))
    // if (appliedFilters.filteredDate !== undefined && !availableDates.map(date => date.toISO()).includes(appliedFilters.filteredDate?.toISO())) {
    //   setAndApplyFilters({ filteredDate: availableDates[0] })
    // }
  }, [ setFilterOptions, setAndApplyFilters, availableLocations, availableDietaryTags, availableTemperatureTags, availableMenuTypes/*, availableDates, appliedFilters.filteredDate*/ ])

  const filteredMenuProducts = useMemo(() => filterData(data.availableMenuProducts).sort((a, b) => {
    if (a.availabilityDates.length === b.availabilityDates.length) {
      return a.product.price - b.product.price
    }

    return a.availabilityDates.length - b.availabilityDates.length
  }), [filterData, data.availableMenuProducts])

  const groupedDates: { [x: string]: MenuProduct[] } = useMemo(() => {
    const dateRange = Interval.fromDateTimes(filters.appliedFilters.startDate, filters.appliedFilters.endDate)

    return dateRange.splitBy({ day: 1 }).map(d => d.start).reduce((memo, d) => {
      memo[d.toFormat('yyyy-LL-dd')] ||= []
      return memo
    }, {})
  }, [filters.appliedFilters])

  const groupedMenuProducts: { [x: string]: MenuProduct[] } = useMemo(() => {
    return filteredMenuProducts.reduce((memo, menuProduct) => {
      menuProduct.availabilityDates.forEach((date) => {
        if (!!memo[date]) memo[date].push(menuProduct)
      })

      return memo
    }, groupedDates)
  }, [filteredMenuProducts, groupedDates])

  return {
    ...rest,
    data: {
      ...data,
      filteredMenuProducts,
      groupedMenuProducts,
    },
    filters: {
      ...filters,
      availableDates,
      availableLocations,
      availableMenuTypes,
      availableDietaryTags,
      availableTemperatureTags,
    },
  }
}

export default useFilteredAvailableMenuProducts
