import type { UpdateAccountUserInput, UpdateAccountUserPayload } from '@market/graphql/schema/graphql'
import { updateAccountUserMutation } from '@market/graphql/mutations'
import { MutationTuple, useMarketMutation } from './useMarketMutation'

export type UpdateAccountUserFn = ReturnType<typeof useUpdateAccountUser>[0]
export type UpdateAccountUserData = { updateAccountUser: UpdateAccountUserPayload }

export const useUpdateAccountUser: (variables?: UpdateAccountUserInput) => MutationTuple<UpdateAccountUserData, UpdateAccountUserInput> = (variables)  => {
  const [ updateAccountUserFn, { data, ...result } ] = useMarketMutation<UpdateAccountUserData, UpdateAccountUserInput>(
    updateAccountUserMutation,
    {
      variables,
      // notifyOnNetworkStatusChange: true,
    }
  )

  return [ updateAccountUserFn, { ...result, data } ]
}

export default useUpdateAccountUser
