import { OperationVariables, QueryHookOptions, DocumentNode, TypedDocumentNode } from '@apollo/client'
import { useLazyQuery, LazyQueryResultTuple } from 'hooks/useLazyQuery'
// import useCurrentSchoolLocation from './useCurrentSchoolLocation'

export type { LazyQueryResultTuple }

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useLazyMarketQuery = <TData = any, TVariables = OperationVariables>(query: DocumentNode | TypedDocumentNode<TData, TVariables>, options?: QueryHookOptions<TData, TVariables>): LazyQueryResultTuple<TData, TVariables> => {
  // const { schoolLocation } = useCurrentSchoolLocation()

  return useLazyQuery<TData, TVariables>(query, {
    ...options,
    fetchPolicy: 'cache-and-network',
    context: {
      ...options.context,
      clientName: 'market',
      headers: {
        // 'ORDO-TENANT-ORGANIZATION-ID': schoolLocation?.organization?.id,
        // 'ORDO-TENANT-LOCATION-ID': schoolLocation?.id,
      }
    }
  })
}

export default useLazyMarketQuery