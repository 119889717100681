import type { CancelOrderItemInput, CancelOrderItemPayload } from '@market/graphql/schema/graphql'
import { cancelOrderItemMutation } from '@market/graphql/mutations'
import { MutationTuple, useMarketMutation } from './useMarketMutation'

export type CancelOrderItemFn = ReturnType<typeof useCancelOrderItem>[0]
export type CancelOrderItemData = { cancelOrderItem: CancelOrderItemPayload }

export const useCancelOrderItem: (variables?: CancelOrderItemInput) => MutationTuple<CancelOrderItemData, CancelOrderItemInput> = (variables)  => {
  const [ cancelOrderItemFn, { data, ...result } ] = useMarketMutation<CancelOrderItemData, CancelOrderItemInput>(
    cancelOrderItemMutation,
    {
      variables,
      // notifyOnNetworkStatusChange: true,
    }
  )

  return [ cancelOrderItemFn, { ...result, data } ]
}

export default useCancelOrderItem
