import { gql } from '@market/graphql/schema/gql'

export const adjustOrderItemQuantityMutation = gql(`
  mutation adjustOrderItemQuantityMutation($input: AdjustOrderItemQuantityInput! ) {
    adjustOrderItemQuantity(input: $input) {
      order {
        ...OrderFields
      }
      errors {
        code
        message
        path
      }
    }
  }
`)

export default adjustOrderItemQuantityMutation
