import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { Box, Link, Text, Page } from 'components'
import { LoginForm } from 'compositions/LoginForm'
import OrdoLogo from 'theme/ordo-logo.svg'

export const Login: React.FC = () => {
  return <Page>
    <Box sx={{ width: { md: '600px' }, mx: { md: 'auto' } }}>
      <Box mb={2} textAlign="center">
        <Link component={RouterLink} to="/"><OrdoLogo height="60" /></Link>
      </Box>

      <Text variant="h3" textAlign="center" mb={4}>Sign in</Text>

      <LoginForm />

      <Box display="flex">
        <Text>Don&apos;t have an account? <Link component={RouterLink} to="/register">Create one</Link></Text>
        <Link component={RouterLink} to="/users/password/new" ml="auto">Forgot password?</Link>
      </Box>
    </Box>
  </Page>
}

export default Login
