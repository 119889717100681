import React, { useCallback, useMemo } from 'react'
import { CircularProgress } from '@mui/material'
import { DateTime } from 'luxon'
import {
  Box,
  Button,
  Text,
  Select,
  Currency,
} from 'components'
import { Add as AddIcon } from 'icons'
import { useViewport } from 'hooks'
import { AccountUser, Cart, CartItem, MenuProduct } from '@market/graphql/schema/graphql'
import { useAddCartItems, useUpdateCartItem, useRemoveCartItem } from '@market/hooks'
import { useAccountContext } from '@market/hooks/useCurrentAccount'
import { ProductModal } from '../ProductModal'
import { QuantityButtonGroup } from '../QuantityButtonGroup'

type UserWithCart = {
  accountUser: AccountUser
  cart?: Cart
  cartItem?: CartItem
}

export const AddUserProduct: React.FC<UserWithCart & { menuProduct: MenuProduct, date: string }> = ({ menuProduct, date, accountUser, cartItem }) => {
  const [addCartItems, { loading: addLoading }] = useAddCartItems()
  const [updateCartItem, { loading: updateLoading }] = useUpdateCartItem()
  const [removeCartItem, { loading: removeLoading }] = useRemoveCartItem()
  const loading = useMemo(() => addLoading || updateLoading || removeLoading, [addLoading, updateLoading, removeLoading])

  const handleAddCartItem = useCallback((userId: string, date: string) => {
    addCartItems({
      variables: {
        items: [{
          date,
          userId,
          quantity: 1,
          menuId: menuProduct.menu.id,
          menuProductId: menuProduct.id,
          productId: menuProduct.product.id,
          variantId: menuProduct.variant.id,
          price: menuProduct.product.price,
        }]
      },
    })
  }, [addCartItems, menuProduct])

  const handleIncreaseQuantity = useCallback(() => {
    updateCartItem({ variables: { cartId: cartItem.cartId, itemId: cartItem.id, quantity: cartItem.quantity + 1 } })
  }, [updateCartItem, cartItem])

  const handleDecreaseQuantity = useCallback(() => {
    if (cartItem.quantity === 1) {
      removeCartItem({ variables: { cartId: cartItem.cartId, itemId: cartItem.id } })
    } else {
      updateCartItem({ variables: { cartId: cartItem.cartId, itemId: cartItem.id, quantity: cartItem.quantity - 1 } })
    }
  }, [updateCartItem, removeCartItem, cartItem])

  const handleChangeQuantity = useCallback((quantity: number) => {
    if (quantity === 0) {
      removeCartItem({ variables: { cartId: cartItem.cartId, itemId: cartItem.id } })
    } else {
      updateCartItem({ variables: { cartId: cartItem.cartId, itemId: cartItem.id, quantity: quantity } })
    }
  }, [updateCartItem, removeCartItem, cartItem])


  return <Box display="flex" alignItems="center" justifyContent="flex-end" gap={2} py={1} my={1} sx={(theme) => ({
    borderTopWidth: '1px',
    borderTopStyle: 'solid',
    borderTopColor: theme.palette.divider,
  })}>
    <Text variant="h6" mr="auto" pl={2}>{ accountUser.user.firstName }</Text>
    { !cartItem && <Text>{ DateTime.fromISO(date).toFormat('ccc, LLL d') }</Text> }

    <Box py={2}>
      { !!cartItem && <QuantityButtonGroup
        onIncrease={handleIncreaseQuantity}
        onDecrease={handleDecreaseQuantity}
        onChange={handleChangeQuantity}
        disabled={loading}
        value={cartItem.quantity.toString()}
      /> }

      { !cartItem && <Button
        size="small"
        fullWidth={false}
        disabled={loading}
        onClick={() => handleAddCartItem(accountUser.user.id, date)}
        sx={{ borderRadius: '50%', minWidth: '40px', width: '40px', height: '40px', padding: 0, fontSize: 'inherit' }}
        variant="contained"
        color="inherit"
      >
        <AddIcon fontSize="inherit" />
      </Button> }
    </Box>

    <Text minWidth={!!cartItem || loading ? '50px' : undefined} textAlign="right">
      { !loading && !!cartItem && <Currency value={cartItem.subtotalCents / 100} /> }
      { loading && <CircularProgress size={20} /> }
    </Text>
  </Box>
}

export const AddProductModal: React.FC<{
  menuProduct: MenuProduct,
  setDate: (date: string) => void,
  onClose: () => void,
  date: string,
  open?: boolean
}> = ({ menuProduct, date, open, setDate, onClose }) => {
  const { isMedium } = useViewport()
  const { accountUsers, carts } = useAccountContext()

  const usersWithCarts: UserWithCart[] = useMemo(() => {
    return accountUsers?.reduce((memo, acctUser) => {
      if ((acctUser.location?.id || acctUser.user.roster?.term?.location?.id) === menuProduct?.menu?.location?.id) {
        const userCart = carts.find((cart) => cart.user.id === acctUser.user.id)
        const userWithCart = {
          accountUser: acctUser,
          cart: userCart,
          cartItem: userCart?.cartItems?.find((item) => item.menuProduct.id === menuProduct?.id && item.date === date),
        }
  
        memo.push(userWithCart)
      }

      return memo
    }, []) || []
  }, [accountUsers, carts, menuProduct, date])

  if (!menuProduct) return null

  return <ProductModal open={!!open} fullScreen={!isMedium} onClose={onClose} maxWidth="sm" fullWidth product={menuProduct.product}>
    { menuProduct?.availabilityDates?.length > 1 && <Box pb={2}>
      <Text variant="h6" pb={1}>Select a date</Text>

      <Select
        options={menuProduct.availabilityDates.map((dateOpt) => ({
          label: DateTime.fromISO(dateOpt).toFormat('cccc, LLLL d'),
          value: dateOpt,
        }))}
        value={date}
        onChange={(evt) => setDate(evt.target.value.toString())}
      />
    </Box> }

    { menuProduct?.availabilityDates?.length === 1 && <Box>
      <Text variant="h6" pb={2}>{ DateTime.fromISO(date).toFormat('cccc, LLLL d') }</Text>
    </Box> }

    { usersWithCarts.map((userWithCart) => <AddUserProduct
      key={userWithCart.accountUser.id}
      {...userWithCart}
      date={date}
      menuProduct={menuProduct}
    />) }

    <Box sx={(theme) => ({
      display: 'flex',
      pt: 2,
      borderTopWidth: '1px',
      borderTopStyle: 'solid',
      borderTopColor: theme.palette.divider,
    })}>
      <Text>Subtotal</Text>
      <Text ml="auto"><Currency value={usersWithCarts.reduce((memo, userWithCart) => memo + (userWithCart.cartItem?.subtotalCents || 0), 0) / 100} /></Text>
    </Box>
  </ProductModal>
}

export default AddProductModal
