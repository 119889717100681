import React, { useEffect, useMemo, useState } from 'react'
import { FormikConfig, useField } from 'formik'
import { matchSorter } from 'match-sorter'
import {
  Alert,
  Autocomplete,
  Box,
  Form,
  FormTextField,
  FormTelephoneField,
  FormSubmitButton,
  FormSelect,
  TextField,
} from 'components'
import { useStatesProvinces } from 'hooks/useStatesProvinces'
import { useServicedSchools } from '@market/hooks'
import { useAccountContext } from '@market/hooks/useCurrentAccount'
import { AccountUser, ClientError, ListedSchool, Roster, StateProvince } from '@market/graphql/schema/graphql'

export interface AccountUserFormProps {
  form: FormikConfig<object>
  accountUser?: AccountUser
  loading?: boolean
  submitted?: boolean
  errors?: ClientError[]
}

export type Role = {
  role: 'GUARDIAN' | 'STUDENT' | 'STAFF'
  name: string
  description: string
}

export const ROLES: Role[] = [
  {
    role: 'GUARDIAN',
    name: 'Parent',
    description: 'I need to order for my kids',
  },
  {
    role: 'STUDENT',
    name: 'Student',
    description: 'I need to order for myself',
  },
  {
    role: 'STAFF',
    name: 'Staff',
    description: 'I need to order for myself and/or my kids',
  },
]

type RosterSelectorProps = {
  rosterState: { state: StateProvince, location: ListedSchool, roster: Roster }
  statesLoading: boolean
  statesProvinces: StateProvince[]
  schoolsLoading: boolean
  servicedSchools: ListedSchool[]
  rosters: Roster[]
  setRosterState: React.Dispatch<React.SetStateAction<{
    state: StateProvince;
    location: ListedSchool;
    roster: Roster;
  }>>
}

const RosterSelector: React.FC<RosterSelectorProps> = ({ rosterState, statesLoading, statesProvinces, schoolsLoading, servicedSchools, rosters, setRosterState }) => {
  const [ {}, meta ] = useField('role')

  if (meta.value === 'GUARDIAN') return null

  return <Box>
    <Autocomplete
      selectOnFocus
      disabled={statesLoading}
      disableClearable={true}
      onChange={(_e, value: StateProvince) => setRosterState({ ...rosterState, state: value, location: null })}
      value={rosterState.state}
      options={statesProvinces}
      isOptionEqualToValue={(option, value) => option.id === value?.id}
      getOptionLabel={(option: StateProvince) => option.name}
      renderInput={(params) => <TextField {...params} label="State" />}
    />

    <Autocomplete
      selectOnFocus
      disabled={schoolsLoading}
      autoComplete={true}
      disableClearable={true}
      onChange={(_e, value: ListedSchool) => setRosterState({ ...rosterState, location: value })}
      value={rosterState.location}
      options={servicedSchools}
      isOptionEqualToValue={(option, value) => option.id === value?.id}
      getOptionLabel={(option: ListedSchool) => option.name}
      renderInput={(params) => <TextField {...params} label="School Campus" placeholder="Find your school" />}
      filterOptions={(options, { inputValue }) => {
        if (inputValue.length < 2) {
          if (!!rosterState.location) {
            return [rosterState.location]
          } else {
            return []
          }
        }

        return matchSorter(options, inputValue, { keys: ['name'] })
      }}
      noOptionsText="No matching schools"
    />

    { !!rosterState.location && <FormSelect
      id="rosterId"
      name="rosterId"
      label={rosters[0]?.groupName}
      placeholder={rosters[0]?.groupName}
      onChange={(evt) => setRosterState({ ...rosterState, roster: rosters?.find((r) => r.id === evt?.target?.value) || null })}
      value={rosterState.roster?.id}
      options={rosters.map((roster) => ({ value: roster.id, label: roster.description || roster.name }))}
    /> }
  </Box>
}

const CellPhoneField: React.FC = () => {
  const [ {}, meta ] = useField('role')

  if (meta.value === 'STUDENT') return null

  return <Box>
    <FormTelephoneField
      id="phoneNumber"
      name="phoneNumber"
      label="Cell phone (optional)"
      placeholder="Cell phone"
    />
  </Box>
}

const SubmitButton: React.FC<{ loading: boolean }> = ({ loading }) => {
  const [ {}, roleMeta ] = useField('role')
  const [ {}, rosterMeta ] = useField('rosterId')

  return <FormSubmitButton loading={loading} disabled={roleMeta.value !== 'GUARDIAN' && (!rosterMeta.value || rosterMeta.value === '')}>
    Save
  </FormSubmitButton>
}

export const AccountUserForm: React.FC<AccountUserFormProps> = ({ form, loading, submitted, errors, accountUser }) => {
  const { defaultState, defaultLocation } = useAccountContext()
  const { data: { statesProvinces }, loading: statesLoading } = useStatesProvinces()
  const [ loadServicedSchools, { data: { servicedSchools }, loading: schoolsLoading } ] = useServicedSchools()
  const [ rosterState, setRosterState ] = useState<{ state: StateProvince, location: ListedSchool, roster: Roster }>({ state: null, location: null, roster: null })

  const term = useMemo(() => {
    return rosterState.location?.terms[0]
  }, [rosterState.location])

  const rosters = useMemo(() => {
    return term?.rosters?.filter((roster) => roster.isVisible && !roster.isArchived)?.sort((a, b) => a.sortOrder - b.sortOrder) || []
  }, [term])

  useEffect(() => {
    const accountState = statesProvinces?.find((state) => state.id === defaultState)
    const currentState = statesProvinces?.find((state) => state.id === (accountUser?.user?.roster?.term?.location?.address?.stateProvince || accountUser?.location?.address?.stateProvince))
    setRosterState((state) => ({ ...state, state: currentState || accountState || null }))
  }, [accountUser, defaultState, statesProvinces, setRosterState])

  useEffect(() => {
    const accountLocation = servicedSchools?.find((school) => school.locationId === defaultLocation)
    const currentLocation = servicedSchools?.find((school) => school.locationId === (accountUser?.user?.roster?.term?.location?.id || accountUser?.location?.id))
    setRosterState((state) => ({ ...state, location: ((currentLocation || accountLocation) as ListedSchool) || null }))
  }, [accountUser, defaultLocation, servicedSchools, setRosterState])

  useEffect(() => {
    const currentRoster = term?.rosters?.find((roster) => roster.id === accountUser?.user?.roster?.id)
    setRosterState((state) => ({ ...state, roster: (currentRoster as Roster) || null }))
  }, [accountUser, term?.rosters])

  useEffect(() => {
    loadServicedSchools({ variables: { state: rosterState.state?.id } })
  }, [rosterState.state, loadServicedSchools])

  return <Form form={form}>
    <Box>
      <FormSelect
        id="role"
        name="role"
        label="User Role"
        options={ROLES.map((role) => ({ label: role.name, value: role.role }))}
      />

      <FormTextField
        id="firstName"
        name="firstName"
        label="First Name"
      />

      <FormTextField
        id="lastName"
        name="lastName"
        label="Last Name"
      />

      <FormTextField
        id="preferredName"
        name="preferredName"
        label="Preferred Name (optional)"
      />

      <CellPhoneField />

      <RosterSelector
        statesLoading={statesLoading}
        schoolsLoading={schoolsLoading}
        statesProvinces={statesProvinces}
        servicedSchools={servicedSchools as ListedSchool[]}
        rosters={rosters}
        rosterState={rosterState}
        setRosterState={setRosterState}
      />
    </Box>

    { !submitted && <>{ errors.map((error) => <Alert key={`${error.code}:${error.message}`} severity="error" sx={{ width: '100%', mb: 2 }}>
      {error.message}
    </Alert>)}</> }

    <SubmitButton loading={loading} />
  </Form>

}

export default AccountUserForm
