import { gql } from '@market/graphql/schema/gql'

export const createAccountUserMutation = gql(`
  mutation createAccountUserMutation($input: CreateAccountUserInput! ) {
    createAccountUser(input: $input) {
      account {
        id
        accountUsers {
          ...AccountUserFields
        }
      }
      errors {
        code
        message
        path
      }
    }
  }
`)

export default createAccountUserMutation
