import { gql } from '@market/graphql/schema/gql'

export const updateCartItemMutation = gql(`
  mutation updateCartItemMutation($input: UpdateCartItemInput! ) {
    updateCartItem(input: $input) {
      cart {
        ...CartFields
      }
      errors {
        code
        message
        path
      }
    }
  }
`)

export default updateCartItemMutation
