import { useCallback, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router'
import apolloClient from 'graphql/client'
import type { LocationWithFromPath } from 'types/routes'
import { useToken } from 'hooks/useToken'
import { z } from 'zod'
import { toFormikValidationSchema } from 'zod-formik-adapter'
import { useAxiosForm, AxiosFormConfig, AxiosFormTuple, OnSuccessFn, CoerceFn } from 'hooks/useAxiosForm'

export const zodSchema = z.object({
  firstName: z.string({
    required_error: 'First name is required',
  }).nonempty(),
  lastName: z.string({
    required_error: 'Last name is required',
  }).nonempty(),
  email: z.string({
    required_error: 'Email is required',
  }).nonempty()
  .email('Email address is invalid'),
  password: z.string({
    required_error: 'Password is required',
  }).nonempty().min(6),
  passwordConfirmation: z.string({
    required_error: 'Password confirmation is required',
  }).nonempty().min(6),
}).superRefine(({ password, passwordConfirmation }, ctx) => {
  if (password !== passwordConfirmation) {
    ctx.addIssue({
      code: 'custom',
      message: 'Passwords do not match',
      path: ['passwordConfirmation']
    })
  }
})

export const validationSchema = toFormikValidationSchema(zodSchema)

type RegistrationResponseData = {
  token?: string
  refresh?: string
}

type RegistrationValues = {
  firstName: string
  lastName: string
  email: string
  password: string
  passwordConfirmation: string
}

type RegistrationVariables = {
  user: {
    first_name: string
    last_name: string
    email: string
    password: string
    password_confirmation: string
    roles: ['ADMIN']
  }
}

export const useRegistrationForm = (config?: AxiosFormConfig<RegistrationResponseData, RegistrationVariables, RegistrationValues>): AxiosFormTuple<RegistrationResponseData, RegistrationVariables, RegistrationValues> => {
  const { token, valid, setToken } = useToken()
  const { resetStore } = apolloClient(token)
  const navigate = useNavigate()
  const location: LocationWithFromPath = useLocation()
  const from = location.state?.from?.pathname ? `${location.state?.from?.pathname}${location.state?.from?.search ? location.state?.from?.search : ''}` : '/'

  const onSuccess: OnSuccessFn<RegistrationResponseData, RegistrationVariables, RegistrationValues> = useCallback((data) => {
    if (data.token) {
      setToken(data.token, data.refresh)
      resetStore()
    }
  }, [setToken, resetStore])

  const coerce: CoerceFn<RegistrationVariables, RegistrationValues> = (values) => {
    return {
      user: {
        first_name: values.firstName,
        last_name: values.lastName,
        email: values.email,
        password: values.password,
        password_confirmation: values.passwordConfirmation,
        roles: ['ADMIN'],
      }
    }
  }

  const [ axiosForm, axiosResult] = useAxiosForm<RegistrationResponseData, RegistrationVariables, RegistrationValues>({
    url: '/users',
    method: 'post',
  }, {
    ...config,
    coerce,
    onSuccess,
    initialValues: { firstName: '', lastName: '', email: '', password: '', passwordConfirmation: '' }
  })

  useEffect(() => {
    if (valid) navigate(from, { replace: true })
  }, [valid, from, navigate])

  return [ { ...axiosForm, validationSchema }, axiosResult ]
}

export default useRegistrationForm
