import { gql } from '@market/graphql/schema/gql'

export const checkoutCartsMutation = gql(`
  mutation checkoutCartsMutation($input: CheckoutCartsInput! ) {
    checkoutCarts(input: $input) {
      orders {
        ...OrderFields
      }
      errors {
        code
        message
        path
      }
    }
  }
`)

export default checkoutCartsMutation
