import { createContext, useCallback, useContext as useReactContext, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { IconProps } from '@mui/material'
import { useGlobalContext } from 'hooks/useGlobalContext'
import { useAccountContext } from './useCurrentAccount'
import { Account } from '@market/graphql/schema/graphql'

export type Step = 'profile' | 'family'

export type WizardStep<S = Step> = {
  step: S
  name: string
  available: (account: Account) => boolean
  completed: (account: Account) => boolean
}

export type StepComponents = {
  [key in Step]: React.FC<{ wizard: ReturnType<typeof useAccountOnboardingWizard> }>
}

export type StepIcons = {
  [key in Step]: React.FC<IconProps>
}

export const steps: WizardStep[] = [
  {
    step: 'profile',
    name: 'Setup your profile',
    available: () => true,
    completed: (account) => account.accountUsers?.some((acctUser) => acctUser.roles.some((role) => ['GUARDIAN', 'STUDENT', 'STAFF'].includes(role))),
  },
  {
    step: 'family',
    name: 'Manage your family',
    available: (account) => account.accountUsers?.some((acctUser) => acctUser.roles.some((role) => ['GUARDIAN', 'STUDENT', 'STAFF'].includes(role))),
    completed: (account) => account.accountUsers?.some((acctUser) => acctUser.roles.some((role) => ['STUDENT', 'STAFF'].includes(role))),
  },
]

export type UseAccountOnboardingWizard = ReturnType<typeof useAccountOnboardingWizard>

export const AccountOnboardingContext = createContext<UseAccountOnboardingWizard>({} as UseAccountOnboardingWizard)
export const AccountOnboardingContextProvider = AccountOnboardingContext.Provider
export const useContext = () => useReactContext(AccountOnboardingContext)

export const useAccountOnboardingWizard = ({ step }: { step: Step }) => {
  const navigateTo = useNavigate()
  const activeStep = useMemo(() => steps.find((s) => s.step === step), [step])
  const stepNumber = useMemo(() => steps.findIndex((s) => s.step === step) || 0, [step])
  const nextStep = useMemo(() => stepNumber === steps.length ? null : steps[stepNumber + 1], [stepNumber])
  const prevStep = useMemo(() => stepNumber === 0 ? null : steps[stepNumber - 1], [stepNumber])
  const { selectedState, setSelectedState, selectedSchool, setSelectedSchool } = useGlobalContext()
  const accountContext = useAccountContext()

  const navigateToNext = useCallback(() => {
    if (nextStep) {
      navigateTo(`/account/setup/${nextStep.step}`, { replace: false })
    }
  }, [ nextStep, navigateTo ])

  const navigateToPrev = useCallback(() => {
    if (prevStep) {
      navigateTo(`/account/setup/${prevStep.step}`, { replace: false })
    } else {
      navigateTo(`/account/setup`, { replace: false })
    }
  }, [ prevStep, navigateTo ])

  return {
    ...accountContext,
    steps,
    step,
    stepNumber,
    activeStep,
    prevStep,
    nextStep,
    selectedState,
    selectedSchool,
    setSelectedState,
    setSelectedSchool,
    navigateToNext,
    navigateToPrev,
  }
}

export default useAccountOnboardingWizard