import type { CancelOrderInput, CancelOrderPayload } from '@market/graphql/schema/graphql'
import { cancelOrderMutation } from '@market/graphql/mutations'
import { MutationTuple, useMarketMutation } from './useMarketMutation'

export type CancelOrderFn = ReturnType<typeof useCancelOrder>[0]
export type CancelOrderData = { cancelOrder: CancelOrderPayload }

export const useCancelOrder: (variables?: CancelOrderInput) => MutationTuple<CancelOrderData, CancelOrderInput> = (variables)  => {
  const [ cancelOrderFn, { data, ...result } ] = useMarketMutation<CancelOrderData, CancelOrderInput>(
    cancelOrderMutation,
    {
      variables,
      // notifyOnNetworkStatusChange: true,
    }
  )

  return [ cancelOrderFn, { ...result, data } ]
}

export default useCancelOrder
