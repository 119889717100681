import React, { useEffect } from 'react'
import { Route, Routes, useParams } from 'react-router-dom'
import { withSentryReactRouterV6Routing } from '@sentry/react'
import { useRegistrationWizard } from '@market/hooks/useRegistrationWizard'
import { ContextProvider } from './ContextProvider'
import { WizardStep } from './WizardStep'
import { Step } from '@market/hooks/useRegistrationWizard'

const SentryRoutes = withSentryReactRouterV6Routing(Routes)

export const WizardRoute: React.FC = () => {
  const { step } = useParams()
  const wizard = useRegistrationWizard({ step: (step as Step) || 'campus' })

  useEffect(() => {
    if (!wizard?.activeStep?.available()) {
      wizard.navigateToPrev()
    }
  }, [wizard])

  return <ContextProvider {...wizard}>
    <WizardStep />
  </ContextProvider>
}

export const RegistrationWizard: React.FC = () => {
  return <SentryRoutes>
    <Route path="/" element={<WizardRoute />} />
    <Route path="/:step" element={<WizardRoute />} />
  </SentryRoutes>

}

export default RegistrationWizard
