import { gql } from '@market/graphql/schema/gql'

export const updateAccountUserMutation = gql(`
  mutation updateAccountUserMutation($input: UpdateAccountUserInput! ) {
    updateAccountUser(input: $input) {
      accountUser {
        ...AccountUserFields
      }
      errors {
        code
        message
        path
      }
    }
  }
`)

export default updateAccountUserMutation
