import React from 'react'
import { Page, Text } from 'components'
import { useAccountContext } from '@market/hooks/useCurrentAccount'
import { SubscriptionsList } from '@market/compositions/Subscriptions'

export const Subscriptions: React.FC = () => {
  const { account, subscriptionUsers } = useAccountContext()

  return <Page>
    <Text variant="h4">Manage Subscriptions</Text>

    <SubscriptionsList account={account} users={subscriptionUsers} />
  </Page>
}

export default Subscriptions
