import { createContext, useCallback, useContext as useReactContext, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { IconProps } from '@mui/material'
import { useGlobalContext } from 'hooks/useGlobalContext'

export type Step = 'campus' | 'signup'

export type WizardStep<S = Step> = {
  step: S
  name: string
  available: () => boolean
  completed: () => boolean
}

export type StepComponents = {
  [key in Step]: React.FC<{ wizard: ReturnType<typeof useRegistrationWizard> }>
}

export type StepIcons = {
  [key in Step]: React.FC<IconProps>
}

export const steps: WizardStep[] = [
  {
    step: 'campus',
    name: 'Find your school',
    available: () => true,
    completed: () => false,
  },
  {
    step: 'signup',
    name: 'Sign Up',
    available: () => true,
    completed: () => false,
  },
]

export type UseRegistrationWizard = ReturnType<typeof useRegistrationWizard>

export const RegistrationContext = createContext<UseRegistrationWizard>({} as UseRegistrationWizard)
export const RegistrationContextProvider = RegistrationContext.Provider
export const useContext = () => useReactContext(RegistrationContext)

export const useRegistrationWizard = ({ step }: { step: Step }) => {
  const navigateTo = useNavigate()
  const activeStep = useMemo(() => steps.find((s) => s.step === step), [step])
  const stepNumber = useMemo(() => steps.findIndex((s) => s.step === step) || 0, [step])
  const nextStep = useMemo(() => stepNumber === steps.length ? null : steps[stepNumber + 1], [stepNumber])
  const prevStep = useMemo(() => stepNumber === 0 ? null : steps[stepNumber - 1], [stepNumber])
  const { selectedState, setSelectedState, selectedSchool, setSelectedSchool } = useGlobalContext()

  const navigateToNext = useCallback(() => {
    if (nextStep) {
      navigateTo(`/register/${nextStep.step}`, { replace: false })
    }
  }, [ nextStep, navigateTo ])

  const navigateToPrev = useCallback(() => {
    if (prevStep) {
      navigateTo(`/register/${prevStep.step}`, { replace: false })
    } else {
      navigateTo(`/register`, { replace: false })
    }
  }, [ prevStep, navigateTo ])

  return {
    steps,
    step,
    stepNumber,
    activeStep,
    prevStep,
    nextStep,
    selectedState,
    selectedSchool,
    setSelectedState,
    setSelectedSchool,
    navigateToNext,
    navigateToPrev,
  }
}

export default useRegistrationWizard