import React from 'react'
import { Page } from 'components'
import { AccountOnboardingWizard } from '@market/compositions/AccountOnboardingWizard'

export const Onboarding: React.FC = () => {
  return <Page sx={{ width: { md: 'auto' }, maxWidth: { md: 'auto' } }}>
    <AccountOnboardingWizard />
  </Page>
}

export default Onboarding
