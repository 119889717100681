import type { ClearCartInput, ClearCartPayload } from '@market/graphql/schema/graphql'
import { clearCartMutation } from '@market/graphql/mutations'
import { MutationTuple, useMarketMutation } from './useMarketMutation'

export type ClearCartFn = ReturnType<typeof useClearCart>[0]
export type ClearCartData = { clearCart: ClearCartPayload }

export const useClearCart: (variables?: ClearCartInput) => MutationTuple<ClearCartData, ClearCartInput> = (variables)  => {
  const [ clearCartFn, { data, ...result } ] = useMarketMutation<ClearCartData, ClearCartInput>(
    clearCartMutation,
    {
      variables,
      // notifyOnNetworkStatusChange: true,
    }
  )

  return [ clearCartFn, { ...result, data } ]
}

export default useClearCart
