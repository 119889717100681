import React, { useCallback, useMemo, useState } from 'react'
import { Image } from 'mui-image'
import { CircularProgress } from '@mui/material'
import {
  Box,
  Button,
  Currency,
  ConfirmDialog,
  Text,
} from 'components'
import { Order, OrderProduct } from '@market/graphql/schema/graphql'
import { useAdjustOrderItemQuantity, useCancelOrderItem } from '@market/hooks'
import { QuantityButtonGroup, QuantityChip } from '@market/components'

export type OrderItemProps = {
  order?: Order
  orderProduct: OrderProduct
}

export const OrderItem: React.FC<OrderItemProps> = ({ orderProduct }) => {
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)
  const [updateOrderItem, { loading: updateLoading }] = useAdjustOrderItemQuantity()
  const [cancelOrderItem, { loading: cancelLoading }] = useCancelOrderItem()
  const loading = useMemo(() => updateLoading || cancelLoading, [updateLoading, cancelLoading])

  const handleClickAdd = useCallback(() => {
    updateOrderItem({ variables: { id: orderProduct.id, quantity: orderProduct.quantity + 1 } })
  }, [updateOrderItem, orderProduct])

  const handleClickSubtract = useCallback(() => {
    if (orderProduct.quantity === 1) {
      setConfirmDialogOpen(true)
    } else {
      updateOrderItem({ variables: { id: orderProduct.id, quantity: orderProduct.quantity - 1 } })
    }
  }, [updateOrderItem, setConfirmDialogOpen, orderProduct])

  const handleChangeQuantity = useCallback((quantity: number) => {
    if (quantity === 0) {
      setConfirmDialogOpen(true)
    } else {
      updateOrderItem({ variables: { id: orderProduct.id, quantity: quantity } })
    }
  }, [updateOrderItem, setConfirmDialogOpen, orderProduct])

  return <Box
    display="flex"
    flexWrap="nowrap"
    alignItems="center"
    gap={1}
    sx={(theme) => ({
      opacity: orderProduct.computedStatus === "CANCELLED" ? 0.5 : 1,
      borderBottomWidth: '1px',
      borderBottomStyle: 'solid',
      borderBottomColor: theme.palette.divider,
      py: { xs: 0, md: 2 },
      pr: { xs: 2, md: 0 }
    })}
  >
    <ConfirmDialog
      open={confirmDialogOpen}
      onClose={() => setConfirmDialogOpen(false)}
      content={<Text>Are you sure you want to cancel this item?</Text>}
      action={<Button color="error" onClick={() => {
        cancelOrderItem({ variables: { id: orderProduct.id } })
        setConfirmDialogOpen(false)
      }}>Cancel item</Button>}
    />

    <Box flexShrink={0} width={{ xs: '120px', md: '100px' }} height={{ xs: '120px', md: '100px' }}>
      <Image
        src={orderProduct.menuProduct.product.featuredImage}
        duration={0}
        width="100%"
        height="100%"
        sx={{ borderRadius: { md: '10px' } }}
      />
    </Box>

    <Box flexGrow={1} display="flex" flexDirection="column">
      <Box pb={1}>
        <Text>{ orderProduct.menuProduct.product.name }</Text>
      </Box>

      <Box display="flex" flexWrap="nowrap" alignItems="center">
        <Box>
          { orderProduct.userAdjustable && <QuantityButtonGroup
            onIncrease={handleClickAdd}
            onDecrease={handleClickSubtract}
            onChange={handleChangeQuantity}
            disabled={loading || orderProduct.status === 'cancelled'}
            value={orderProduct.quantity.toString()}
          /> }

          { !orderProduct.userAdjustable && <QuantityChip label={orderProduct.quantity} /> }
        </Box>

        <Box ml="auto">
          <Text>{ loading ? <CircularProgress size={20} /> : <Currency value={orderProduct.fullSubtotalCents / 100} currency={orderProduct.fullSubtotalCurrency} />}</Text>
        </Box>
      </Box>
    </Box>
  </Box>
}

export default OrderItem
