import { useMemo } from 'react'
import { DateTime } from 'luxon'
import { QueryHookOptions } from '@apollo/client'
import { accountPaymentMethodsQuery } from '@market/graphql/queries'
import type { AccountPaymentMethods, AccountPaymentMethodsQueryQueryVariables } from '@market/graphql/schema/graphql'
import { useMarketQuery } from './useMarketQuery'

type AccountPaymentMethodsArgs = AccountPaymentMethodsQueryQueryVariables & QueryHookOptions

export const useAccountPaymentMethods = ({ accountId, pollInterval }: AccountPaymentMethodsArgs) => {
  const queryResult = useMarketQuery<{ accountPaymentMethods: AccountPaymentMethods }, AccountPaymentMethodsQueryQueryVariables>(
    accountPaymentMethodsQuery,
    {
      variables: { accountId },
      pollInterval,
    }
  )

  const payload = useMemo(() => ({
    ...queryResult,
    loadedAt: DateTime.now(),
    data: {
      accountPaymentMethods: {
        setupIntent: {},
        paymentMethods: [],
        ...queryResult.data?.accountPaymentMethods
      },
      ...queryResult.data,
    }
  }), [queryResult])

  return payload
}

export default useAccountPaymentMethods
