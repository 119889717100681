import { MenuProduct } from '@market/graphql/schema/graphql'
import { useCallback, useEffect, useReducer } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

export type AddProductModalState = {
  open: boolean
  loading: boolean
  menuProduct?: MenuProduct
  date?: string
  errors?: string[]
}

export type AddProductModalAction =
  | { type: 'OPEN_MODAL', payload: { menuProduct: MenuProduct, date: string } }
  | { type: 'CLOSE_MODAL' }
  | { type: 'SET_DATE', payload: string }
  | { type: 'SET_LOADING', payload: boolean }
  | { type: 'SET_ERRORS', payload: string[] }
  | { type: 'CLEAR_ERRORS' }

const defaultState: AddProductModalState = {
  open: false,
  loading: false,
}

const addProducModalReducer = (state: AddProductModalState, action: AddProductModalAction) => {
  switch(action.type) {
    case 'OPEN_MODAL':
      return {
        ...state,
        open: true,
        menuProduct: action.payload.menuProduct,
        date: action.payload.date,
      }

    case 'CLOSE_MODAL':
      return {
        ...state,
        open: false,
        menuProduct: undefined,
        date: undefined,
        loading: undefined,
        errors: undefined,
      }

    case 'SET_DATE':
      return {
        ...state,
        date: action.payload,
      }

    case 'SET_LOADING':
      return {
        ...state,
        loading: action.payload,
      }

    case 'SET_ERRORS':
      return {
        ...state,
        errors: action.payload,
      }

    case 'CLEAR_ERRORS':
      return {
        ...state,
        errors: undefined,
      }

    default:
      return state
  }
}

export const useAddProductModal = (initialState?: Partial<AddProductModalState>) => {
  const location = useLocation()
  const navigateTo = useNavigate()
  const [ state, dispatch ] = useReducer(addProducModalReducer, { ...defaultState, ...initialState })

  const openModal = useCallback((menuProduct: MenuProduct, date: string) => dispatch({ type: 'OPEN_MODAL', payload: { menuProduct, date } }), [dispatch])
  const closeModal = useCallback(() => dispatch({ type: 'CLOSE_MODAL' }), [dispatch])
  const setDate = useCallback((date: string) => dispatch({ type: 'SET_DATE', payload: date }), [dispatch])
  const setLoading = useCallback((loading: boolean) => dispatch({ type: 'SET_LOADING', payload: loading }), [dispatch])
  const setErrors = useCallback((errors: string[]) => dispatch({ type: 'SET_ERRORS', payload: errors }), [dispatch])
  const clearErrors = useCallback(() => dispatch({ type: 'CLEAR_ERRORS' }), [dispatch])

  useEffect(() => {
    if (location.state?.open) {
      openModal(location.state.menuProduct, location.state.date || location.state.menuProduct?.availabilityDates[0])
    } else {
      closeModal()
    }
  }, [location, openModal, closeModal])

  const navOpenModal = (menuProduct: MenuProduct, date: string) => {
    navigateTo(location.pathname, { state: { ...location.state, open: true, menuProduct, date } })
  }

  const navCloseModal = () => {
    closeModal()
    navigateTo(-1)
  }

  return {
    ...state,
    openModal: navOpenModal,
    closeModal: navCloseModal,
    setDate,
    setLoading,
    setErrors,
    clearErrors,
  }
}

export type UseAddProductModalReturn = ReturnType<typeof useAddProductModal>

export default useAddProductModal
