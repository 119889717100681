import React, { useEffect } from 'react'
import { Box } from 'components'
import { Stepper, MobileStepper } from './Stepper'
import type { UseSubscriptionWizard, StepComponents, Step } from '@market/hooks/useSubscriptionWizard'
import { useSubscriptionWizard } from '@market/hooks/useSubscriptionWizard'
import { useViewport } from 'hooks'
import { AccountUser } from '@market/graphql/schema/graphql'
import { PaymentMethodStep } from './PaymentMethodStep'
import { EditSubscriptionStep } from './EditSubscriptionStep'
import { TasteProfileStep } from './TasteProfileStep'
import { ActivateStep } from './ActivateStep'

export const stepComponents: StepComponents = {
  payment: PaymentMethodStep,
  options: EditSubscriptionStep,
  profile: TasteProfileStep,
  activate: ActivateStep,
}

export interface StepProps {
  accountUser: AccountUser
  step: Step
}

export const DesktopStep: React.FC<{ wizard: UseSubscriptionWizard }> = ({ wizard }) => {
  const StepComponent = stepComponents[wizard.step]

  return <Box height="100%" display="flex" alignItems="stretch">
    <Box pl={3} pr={4}>
      <Stepper wizard={wizard} />
    </Box>

    <Box pl={4} flexGrow={1}>
      { wizard.activeStep?.available(wizard.accountUser) && <StepComponent wizard={wizard} /> }
    </Box>
  </Box>
}

export const MobileStep: React.FC<{ wizard: UseSubscriptionWizard }> = ({ wizard }) => {
  const StepComponent = stepComponents[wizard.step]

  return <Box height="100%" position="relative">
    <MobileStepper wizard={wizard} position="top" />

    <Box pt={8} width="100%">
      { wizard.activeStep?.available(wizard.accountUser) && <StepComponent wizard={wizard} /> }
    </Box>
  </Box>
}

export const WizardStep: React.FC<StepProps> = ({ accountUser, step }) => {
  const wizard = useSubscriptionWizard({ accountUser, step })
  const { isTablet } = useViewport()

  useEffect(() => {
    if (!wizard?.activeStep?.available(wizard.accountUser)) {
      wizard.navigateToPrev()
    }
  }, [wizard])

  return isTablet ? <DesktopStep wizard={wizard} /> : <MobileStep wizard={wizard} />
}

export default WizardStep
