import { gql } from '@market/graphql/schema/gql'

export const servicedSchoolsQuery = gql(`
  query servicedSchoolsQuery($state: String, $locationId: String) {
    servicedSchools(state: $state, locationId: $locationId) {
      id
      name
      locationId
      createdAt
      updatedAt
      terms {
        id
        name
        rosters {
          id
          name
          description
          group
          groupName
          sortOrder
          isVisible
          isArchived
        }
      }
    }
  }
`)

export default servicedSchoolsQuery
