import type { RemoveCartItemInput, RemoveCartItemPayload } from '@market/graphql/schema/graphql'
import { removeCartItemMutation } from '@market/graphql/mutations'
import { MutationTuple, useMarketMutation } from './useMarketMutation'

export type RemoveCartItemFn = ReturnType<typeof useRemoveCartItem>[0]
export type RemoveCartItemData = { removeCartItem: RemoveCartItemPayload }

export const useRemoveCartItem: (variables?: RemoveCartItemInput) => MutationTuple<RemoveCartItemData, RemoveCartItemInput> = (variables)  => {
  const [ removeCartItemFn, { data, ...result } ] = useMarketMutation<RemoveCartItemData, RemoveCartItemInput>(
    removeCartItemMutation,
    {
      variables,
      // notifyOnNetworkStatusChange: true,
    }
  )

  return [ removeCartItemFn, { ...result, data } ]
}

export default useRemoveCartItem
