import { gql } from '@market/graphql/schema/gql'

export const accountOrdersQuery = gql(`
  query accountOrdersQuery($accountId: ID!, $filters: QueryFiltersInput, $sorting: [QuerySortInput], $page: Int, $pageSize: Int) {
    accountOrders(accountId: $accountId, filters: $filters, sorting: $sorting, page: $page, pageSize: $pageSize) {
      rows {
        ...OrderFields
      }
      rowCount
      pageInfo {
        hasNextPage
        truncated
      }
      aggregations {
        field
        buckets {
          label
          value
          count
          total
        }
      }
    }
  }
`)

export default accountOrdersQuery
