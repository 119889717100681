import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'
import {
  Box,
  Button,
  Currency,
  IconButton,
  Text,
  EmptyState,
} from 'components'
import { useDrawerSettings } from 'hooks'
import { Cart as CartIcon, Close as CloseIcon } from 'icons'
import { ContentDrawerPortal } from 'compositions/ContentDrawer'
import { useAccountContext } from '@market/hooks/useCurrentAccount'
import { UserCart } from './UserCart'

export const Carts: React.FC = () => {
  const [{}, { setContentDrawer }] = useDrawerSettings()
  const { me: { token }, carts } = useAccountContext()

  const cartsQuantity = useMemo(() => carts.reduce((memo, cart) => {
    return memo + cart.quantity
  }, 0), [carts])

  const cartsTotal = useMemo(() => carts.reduce((memo, cart) => {
    return memo + cart.totalCents
  }, 0), [carts])

  if (!token) return null

  return <>
    <ContentDrawerPortal overflow="hidden" icon={() => <><CartIcon />{ cartsQuantity > 0 ? <> &#x2022; { cartsQuantity }</> : null }</>} drawerType="carts">
      <Box height="100%" display="flex" flexDirection="column">
        <Box display="flex" flexDirection="column" overflow="auto" flexGrow={1}>
          <Box sx={(theme) => ({
            display: 'flex',
            alignItems: 'center',
            p: 2,
            mb: 2,
            borderBottomWidth: '1px',
            borderBottomStyle: 'solid',
            borderBottomColor: theme.palette.divider,
          })}>
            <Box>
              <Text variant="h6" fontWeight="medium">Cart</Text>
            </Box>

            <Box ml="auto">
              <IconButton onClick={() => setContentDrawer(false, 'carts')}><CloseIcon /></IconButton>
            </Box>
          </Box>

          { cartsQuantity === 0 && <Box height="100%">
            <EmptyState icon={CartIcon} message="Your cart is empty." />
          </Box> }

          { cartsQuantity > 0 && <Box>
            { carts.map((cart) => <UserCart key={cart.id} cart={cart} /> ) }
          </Box> }
        </Box>

        <Box p={2}>
          <Button
            variant="contained"
            component={Link}
            to="/checkout"
            onClick={() => setContentDrawer(false, 'carts')}
            disabled={cartsQuantity === 0}
            sx={{ display: 'flex', justifyContent: 'space-between'}}
          ><Text>Checkout</Text> <Currency value={cartsTotal / 100} /></Button>
        </Box>
      </Box>
    </ContentDrawerPortal>
  </>
}

export default Carts
