import React from 'react'
import { Page } from 'components'
import { useAccountContext } from '@market/hooks/useCurrentAccount'
import { Checkout as CheckoutPage } from '@market/compositions/Checkout'

export const Checkout: React.FC = () => {
  const { account, carts, paymentMethods, setupIntent } = useAccountContext()

  return <Page fullWidth>
    <CheckoutPage account={account} carts={carts} paymentMethods={paymentMethods} setupIntent={setupIntent} />
  </Page>
}

export default Checkout
