import React from 'react'
import { Page } from 'components'
import { useAccountContext } from '@market/hooks/useCurrentAccount'
import { Orders as OrdersPage } from '@market/compositions/Orders'

export const Orders: React.FC = () => {
  const { account, locations } = useAccountContext()

  return <Page fullWidth unpadded>
    <OrdersPage account={account} locations={locations} />
  </Page>
}

export default Orders
