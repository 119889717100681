import { enqueueSnackbar } from 'notistack'
import { z } from 'zod'
import { toFormikValidationSchema } from 'zod-formik-adapter'
import { useMutationForm, MutationFormTuple, CoerceFn, MutationFormConfig, OnSuccessFn } from 'hooks/useMutationForm'
import { CreateAccountUserInput, Account } from '@market/graphql/schema/graphql'
import { useCreateAccountUser, CreateAccountUserData } from './useCreateAccountUser'

export const zodSchema = z.object({
  accountId: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  preferredName: z.string().optional(),
  phoneNumber: z.string().optional(),
  role: z.string(),
  rosterId: z.string().optional(),
}).superRefine(({ role, rosterId }, ctx) => {
  if (['STUDENT', 'STAFF'].includes(role) && (!rosterId || rosterId === '')) {
    ctx.addIssue({
      code: 'custom',
      message: 'Please select one',
      path: ['rosterId']
    })
  }
})

export type CreateAccountUserFormInput = Required<z.infer<typeof zodSchema>>

export const validationSchema = toFormikValidationSchema(zodSchema)

export const useCreateAccountUserForm = (account: Account, config?: MutationFormConfig<CreateAccountUserData, CreateAccountUserInput, CreateAccountUserFormInput>): MutationFormTuple<CreateAccountUserData, CreateAccountUserFormInput> => {
  const coerce: CoerceFn<CreateAccountUserInput, CreateAccountUserFormInput> = ({ accountId, ...values }) => {
    const coerced: CreateAccountUserInput = { accountId, accountUser: values }

    return coerced  
  }

  const onSuccess: OnSuccessFn<CreateAccountUserData, CreateAccountUserFormInput> = (data, helpers) => {
    enqueueSnackbar("User updated", { variant: "success", autoHideDuration: 2000, preventDuplicate: true })
    if (config?.onSuccess) config.onSuccess(data, helpers)
  }

  const [ mutationForm, mutationResult] = useMutationForm<CreateAccountUserData, CreateAccountUserInput, CreateAccountUserFormInput>(useCreateAccountUser, {
    ...config,
    coerce,
    onSuccess,
    validationSchema,
    initialValues: {
      accountId: account.id,
      firstName: '',
      lastName: '',
      phoneNumber: '',
      preferredName: '',
      role: 'STUDENT',
      rosterId: '',
    }
  })

  return [ mutationForm, mutationResult ]
}

export default useCreateAccountUserForm