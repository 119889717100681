import React, { useMemo } from 'react'
import { IconProps } from '@mui/material'
import {
  Album,
  Architecture,
  Brush,
  Celebration,
  ColorLens,
  Extension,
  Park,
  Pets,
  SportsBaseball,
  SportsFootball,
  Star,
  Toys,
  VideogameAsset,
} from '@mui/icons-material'
import {
  lightBlue,
  lightGreen,
  orange,
  pink,
  purple,
  yellow,
} from '@mui/material/colors'
import { Box, BoxProps } from 'components/Box'

const ColorMap = {
  d: lightBlue,
  h: yellow,
  l: orange,
  p: pink,
  t: purple,
  z: lightGreen,
}

const IconMap = {
  b: Album,
  d: Architecture,
  f: Brush,
  h: Celebration,
  j: ColorLens,
  l: Extension,
  n: Park,
  p: Pets,
  r: SportsBaseball,
  t: SportsFootball,
  v: Star,
  x: Toys,
  z: VideogameAsset,
}

export interface AvatarIconImageProps extends BoxProps {
  name?: string
  iconProps?: IconProps
}

export interface AvatarIconProps extends IconProps {
  name?: string
}

export const AvatarIconImage: React.FC<AvatarIconImageProps> = ({ name, color, fontSize, sx, iconProps, ...props }) => {
  const mappedColor = useMemo(() =>
    name ?
      ColorMap[Object.keys(ColorMap).find((char) => char.localeCompare(name.slice(name.length - 1, name.length).toLowerCase()) >= 0)] :
      Object.values(ColorMap)[Math.floor(Math.random() * Object.values(ColorMap).length)],
  [name])

  return <Box
    backgroundColor={color}
    fontSize={fontSize}
    sx={[
      {
        backgroundColor: color || mappedColor[200],
        display: 'flex',
        alignItems: 'stretch',
        textAlign: 'center',
        width: '100%',
        height: 'auto',
        aspectRatio: '1/1',
        borderRadius: '10px',
      },
      ...(Array.isArray(sx) ? sx : [sx])
    ]}
    {...props}
  >
    <AvatarIcon
      name={name}
      sx={[
        {
          margin: 'auto',
          fontSize: '4rem',
        },
        ...(Array.isArray(iconProps?.sx) ? iconProps.sx : [iconProps?.sx])
      ]}
    />
  </Box>
}

export const AvatarIcon: React.FC<AvatarIconProps> = ({ name, color, fontSize, sx, ...props }) => {
  const Icon = useMemo(() =>
    name ?
      IconMap[Object.keys(IconMap).find((char) => char.localeCompare(name.slice(0, 1).toLowerCase()) >= 0)] :
      Object.values(IconMap)[Math.floor(Math.random() * Object.values(IconMap).length)],
  [name])

  const mappedColor = useMemo(() =>
    name ?
      ColorMap[Object.keys(ColorMap).find((char) => char.localeCompare(name.slice(name.length - 1, name.length).toLowerCase()) >= 0)] :
      Object.values(ColorMap)[Math.floor(Math.random() * Object.values(ColorMap).length)],
  [name])

  return <Icon
    {...props}
    color={color}
    fontSize={fontSize}
    sx={[ { color: color || mappedColor[400] }, ...(Array.isArray(sx) ? sx : [sx]) ]}
  />
}

export default AvatarIcon
