import type { AddCartItemsInput, AddCartItemsPayload } from '@market/graphql/schema/graphql'
import { addCartItemsMutation } from '@market/graphql/mutations'
import { MutationTuple, useMarketMutation } from './useMarketMutation'

export type AddCartItemsFn = ReturnType<typeof useAddCartItems>[0]
export type AddCartItemsData = { addCartItems: AddCartItemsPayload }

export const useAddCartItems: (variables?: AddCartItemsInput) => MutationTuple<AddCartItemsData, AddCartItemsInput> = (variables)  => {
  const [ addCartItemsFn, { data, ...result } ] = useMarketMutation<AddCartItemsData, AddCartItemsInput>(
    addCartItemsMutation,
    {
      variables,
      // notifyOnNetworkStatusChange: true,
    }
  )

  return [ addCartItemsFn, { ...result, data } ]
}

export default useAddCartItems
