import React from 'react'
import { Page } from 'components'
import { RegistrationWizard } from '@market/compositions/RegistrationWizard'

export const Register: React.FC = () => {
  return <Page>
    <RegistrationWizard />
  </Page>
}

export default Register
