import { archivePaymentMethodMutation } from '@market/graphql/mutations'
import { MutationTuple, useMarketMutation } from './useMarketMutation'
import type { ArchivePaymentMethodInput, ArchivePaymentMethodPayload } from '@market/graphql/schema/graphql'

export type ArchivePaymentMethodMutationData = { archivePaymentMethod: ArchivePaymentMethodPayload }

export const useArchivePaymentMethod: (variables?: ArchivePaymentMethodInput) => MutationTuple<ArchivePaymentMethodPayload, ArchivePaymentMethodInput> = (variables)  => {
  const [ archivePaymentMethodFn, { data, ...result } ] = useMarketMutation<ArchivePaymentMethodPayload, ArchivePaymentMethodInput>(
    archivePaymentMethodMutation,
    {
      variables,
      // notifyOnNetworkStatusChange: true
    }
  )

  return [ archivePaymentMethodFn, { ...result, data } ]
}

export default useArchivePaymentMethod
