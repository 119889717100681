import { gql } from '@market/graphql/schema/gql'

export const cancelOrderItemMutation = gql(`
  mutation cancelOrderItemMutation($input: CancelOrderItemInput! ) {
    cancelOrderItem(input: $input) {
      order {
        ...OrderFields
      }
      errors {
        code
        message
        path
      }
    }
  }
`)

export default cancelOrderItemMutation
