import React from 'react'
import { styled } from '@mui/material'
import {
  Box,
  Button,
  ButtonGroup,
  Autocomplete,
  PlainTextField,
} from 'components'
import { Add as AddIcon, Subtract as SubtractIcon } from 'icons'

export type QuantityButtonGroupProps = {
  disabled?: boolean
  value: string
  onIncrease: () => void
  onDecrease: () => void
  onChange: (value: number) => void
}

const QuantityTextField = styled(PlainTextField)(({ theme }) => ({
  '& .MuiInputBase-root': {
    fontSize: 'inherit',
    borderRadius: 0,
    backgroundColor: theme.palette.grey[300],
    '&:hover, &.Mui-focused': {
      backgroundColor: theme.palette.grey[200],
    },
    '&.Mui-disabled': {
      backgroundColor: theme.palette.grey[300],
      '& .MuiInputBase-input': {
        cursor: 'default',
        '&:focus': {
          cursor: 'default'
        },
      },
    },
  },

  '& .MuiOutlinedInput-root.MuiInputBase-sizeSmall': {
    paddingRight: 0,
    paddingLeft: 0,
  },

  '& .MuiInputBase-input': {
    textAlign: 'center',
    fontSize: 'inherit',
    cursor: 'pointer',
    '&:focus': {
      cursor: 'text'
    },
  },

  '& .MuiOutlinedInput-root.MuiInputBase-sizeSmall .MuiAutocomplete-input': {
    paddingRight: '6px',
    paddingLeft: '6px',
  },

  '& .MuiOutlinedInput-notchedOutline': {
    borderWidth: 0,
  },
}))

export const QuantityButtonGroup: React.FC<QuantityButtonGroupProps> = ({ disabled=false, value, onIncrease, onDecrease, onChange }) => {
  return <ButtonGroup size="small" sx={{
    alignItems: 'stretch',
    fontSize: { xs: '1rem', md: '0.9rem' },
    '& .MuiButtonGroup-grouped:not(:first-of-type)': {
      marginLeft: 0,
    },  
  }}>
    <Button
      size="small"
      disabled={disabled}
      onClick={onDecrease}
      sx={{ borderRadius: '50%', fontSize: 'inherit' }}
      variant="contained"
      color="inherit"
    >
      <SubtractIcon fontSize="inherit" />
    </Button>

    <Box>
      <Autocomplete
        freeSolo
        selectOnFocus
        clearOnBlur
        size="small"
        disabled={disabled}
        disableClearable={true}
        forcePopupIcon={false}
        onChange={(_e, value: string) => onChange(parseInt(value))}
        options={['0', '1', '2', '3', '4', '5', '6', '7', '8']}
        value={value}
        renderInput={(params) => <QuantityTextField {...params} required fullWidth={false} />}
      />
    </Box>

    <Button
      size="small"
      disabled={disabled}
      onClick={onIncrease}
      sx={{ borderRadius: '50%', fontSize: 'inherit' }}
      variant="contained"
      color="inherit"
    >
      <AddIcon fontSize="inherit" />
    </Button>
  </ButtonGroup>
}

export default QuantityButtonGroup
