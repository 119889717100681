import React from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Box,
  Button,
  Page,
  Text,
} from 'components'
import OrdoLogo from 'theme/ordo-logo.svg'
import { useFilteredFamily } from '@market/hooks'
import { Family } from '@market/compositions/Family'
import { UseAccountOnboardingWizard } from '@market/hooks/useAccountOnboardingWizard'

export const FamilyStep: React.FC<{ wizard: UseAccountOnboardingWizard }> = ({ wizard }) => {
  const navigateTo = useNavigate()
  const { data: { filteredFamily } } = useFilteredFamily()

  return <Page fullWidth unpadded noMargins>
    <Box sx={{ width: { md: '600px' }, mx: { md: 'auto' } }}>
      <Box mb={2} textAlign="center">
        <OrdoLogo height="60" />
      </Box>

      <Text variant="h3" textAlign="center" mb={2}>{ wizard.activeStep.name }</Text>
      <Text textAlign="center" mb={2}>Please add all family members to your account.</Text>

      <Box>
        <Family family={filteredFamily} />
      </Box>

      <Box my={2}>
        <Button
          disabled={!wizard.activeStep.completed(wizard.account)}
          onClick={() => navigateTo('/menus')}
        >Done!</Button>
      </Box>
    </Box>
  </Page>
}

export default FamilyStep
