import React from 'react'
import { Box, Page, Button, Text } from 'components'
import { UseSubscriptionWizard } from '@market/hooks/useSubscriptionWizard'
import { useActivateSubscription } from '@market/hooks'

export const ActivateStep: React.FC<{ wizard: UseSubscriptionWizard }> = ({ wizard }) => {
  const [activateSubscription, { loading }] = useActivateSubscription()

  return <Page unpadded noMargins>
    <Box width="100%">
      <Text variant="h4">{ wizard.activeStep.name }</Text>
      { wizard.accountUser.subscription.status !== 'enabled' && <Button disabled={loading} loading={loading} onClick={() => activateSubscription({ variables: { subscriptionId: wizard.accountUser.subscription.id } })}>Activate</Button> }
      { wizard.accountUser.subscription.status === 'enabled' && <Text variant="h6">Subscription Activated!</Text>}
    </Box>
  </Page>
}

export default ActivateStep
