import React from 'react'
import { Image } from 'mui-image'
import {
  Box,
  Chip,
  Text,
  IconButton,
  Currency,
} from 'components'
import { Add as AddIcon } from 'icons'
import { MenuProduct } from '@market/graphql/schema/graphql'

export interface MenuProductCardProps {
  menuProduct: MenuProduct
  date: string
  openModal: (menuProduct: MenuProduct, date: string) => void
}

export const MenuProductCard: React.FC<MenuProductCardProps> = ({ menuProduct, date, openModal }) => {
  return <Box display="flex" flexDirection="column" onClick={() => openModal(menuProduct, date)} sx={{ cursor: 'pointer', width: '100%', minWidth: '100%' }}>
    <Box position="relative" sx={{ width: '100%', height: 'auto', aspectRatio: '1/1' }}>
      <Box sx={{
          width: '100%',
          height: 'auto',
          aspectRatio: '1/1',
          borderRadius: '10px',
          overflow: 'hidden',
        }}
      >
        <Image
          src={menuProduct.product.featuredImage}
          duration={0}
          width="100%"
          height="100%"
        />
      </Box>

      <Box position="absolute" bottom="8px" right="8px">
        <IconButton size="small" sx={(theme) => ({
          backgroundColor: theme.palette.background.paper,
          boxShadow: theme.shadows[3],
          '&:hover': {
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[5],
          }
        })}><AddIcon /></IconButton>
      </Box>
    </Box>

    <Box
      sx={(theme) => ({
        py: 1,
        [theme.breakpoints.up('md')]: {
          display: 'flex',
          flexWrap: 'nowrap',
          justifyContent: 'space-between',
          gap: 2,
        },
      })}
    >
      <Box>
        <Text variant="subtitle2" fontWeight="bold">{ menuProduct.product.name }</Text>
      </Box>

      <Box>
        <Text variant="subtitle2" fontWeight="normal"><Currency value={menuProduct.product.price / 100} /></Text>
      </Box>
    </Box>

    <Box display="flex" flexWrap="wrap" gap={1}>
      { menuProduct.product.tags.filter((tag) => tag.type === 'dietary').map((tag) => <Chip key={tag.id} label={tag.name} size="small" sx={{ cursor: 'pointer' }} /> )}
    </Box>
  </Box>
}

export default MenuProductCard
