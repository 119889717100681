import React, { useMemo } from 'react'
import { DateTime } from 'luxon'
import {
  Box,
  Text,
} from 'components'
import { CartItem } from '@market/graphql/schema/graphql'
import { UserCartItem } from './UserCartItem'

export type UserCartDateProps = {
  items: CartItem[]
  date: string
}

export const UserCartDate: React.FC<UserCartDateProps> = ({ items, date }) => {
  const dateTime = useMemo(() => DateTime.fromISO(date), [date])

  return <Box sx={(theme) => ({
    borderBottomWidth: '1px',
    borderBottomStyle: 'solid',
    borderBottomColor: theme.palette.divider,
    mb: 2,
  })}>
    <Box mb={1} px={2}>
      <Text variant="subtitle2">
        { dateTime.toFormat('ccc, LLL d') }
      </Text>
    </Box>

    { items.map((item) => <UserCartItem key={item.id} item={item} />) }
  </Box>
}

export default UserCartDate
