import { gql } from '@market/graphql/schema/gql'

export const clearCartMutation = gql(`
  mutation clearCartMutation($input: ClearCartInput! ) {
    clearCart(input: $input) {
      cart {
        ...CartFields
      }
      errors {
        code
        message
        path
      }
    }
  }
`)

export default clearCartMutation
