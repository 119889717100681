import type { AdjustOrderItemQuantityInput, AdjustOrderItemQuantityPayload } from '@market/graphql/schema/graphql'
import { adjustOrderItemQuantityMutation } from '@market/graphql/mutations'
import { MutationTuple, useMarketMutation } from './useMarketMutation'

export type AdjustOrderItemQuantityFn = ReturnType<typeof useAdjustOrderItemQuantity>[0]
export type AdjustOrderItemQuantityData = { adjustOrderItemQuantity: AdjustOrderItemQuantityPayload }

export const useAdjustOrderItemQuantity: (variables?: AdjustOrderItemQuantityInput) => MutationTuple<AdjustOrderItemQuantityData, AdjustOrderItemQuantityInput> = (variables)  => {
  const [ adjustOrderItemQuantityFn, { data, ...result } ] = useMarketMutation<AdjustOrderItemQuantityData, AdjustOrderItemQuantityInput>(
    adjustOrderItemQuantityMutation,
    {
      variables,
      // notifyOnNetworkStatusChange: true,
    }
  )

  return [ adjustOrderItemQuantityFn, { ...result, data } ]
}

export default useAdjustOrderItemQuantity
