import React, { useCallback, useMemo } from 'react'
import { Image } from 'mui-image'
import {
  Box,
  IconButton,
  Chip,
  Text
} from 'components'
import {
  Like as LikeIcon,
  Liked as LikedIcon,
  Dislike as DislikeIcon,
  Disliked as DislikedIcon,
} from 'icons'
import { AccountUser, Product, ProductReactionEnum } from '@market/graphql/schema/graphql'

type AddProductReactionFn = (productId: Product['id'], reaction: ProductReactionEnum) => void
type RemoveProductReactionFn = (productId: Product['id']) => void

export const ProductCard: React.FC<{
  accountUser: AccountUser,
  product: Product,
  addProductReaction: AddProductReactionFn,
  removeProductReaction: RemoveProductReactionFn
}> = ({ accountUser, product, addProductReaction, removeProductReaction }) => {
  const reaction = useMemo(() => accountUser.user.reactions?.find((reaction) => reaction.productId === product.catalogProductId), [accountUser, product])
  const liked = useMemo(() => reaction?.reaction === 'like', [reaction])
  const disliked = useMemo(() => reaction?.reaction === 'dislike', [reaction])

  const onClickLike = useCallback(() => {
    if (liked) {
      removeProductReaction(product.catalogProductId)
    } else {
      addProductReaction(product.catalogProductId, ProductReactionEnum.Like)
    }
  }, [liked, product, addProductReaction, removeProductReaction])

  const onClickDislike = useCallback(() => {
    if (disliked) {
      removeProductReaction(product.catalogProductId)
    } else {
      addProductReaction(product.catalogProductId, ProductReactionEnum.Dislike)
    }
  }, [disliked, product, addProductReaction, removeProductReaction])


  return <Box display="flex" flexDirection="column" sx={{ width: '100%', minWidth: '100%' }}>
    <Box position="relative" sx={{ width: '100%', height: 'auto', aspectRatio: '1/1' }}>
      <Box sx={{
          width: '100%',
          height: 'auto',
          aspectRatio: '1/1',
          borderRadius: '10px',
          overflow: 'hidden',
        }}
      >
        <Image
          src={product.featuredImage}
          duration={0}
          width="100%"
          height="100%"
        />
      </Box>

      <Box position="absolute" bottom="8px" right="8px">
        <IconButton size="small" onClick={onClickLike} sx={(theme) => ({
          backgroundColor: theme.palette.background.paper,
          boxShadow: theme.shadows[3],
          opacity: liked ? 1 : 0.5,
          '&:hover': {
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[5],
            opacity: 1,
          }
        })}>
          { liked && <LikedIcon fontSize="large" color="error" /> }
          { !liked && <LikeIcon fontSize="large" color="error" /> }
        </IconButton>
      </Box>

      <Box position="absolute" bottom="8px" left="8px">
        <IconButton size="small" onClick={onClickDislike} sx={(theme) => ({
          backgroundColor: theme.palette.background.paper,
          boxShadow: theme.shadows[3],
          opacity: disliked ? 1 : 0.5,
          '&:hover': {
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[5],
            opacity: 1,
          }
        })}>
          { disliked && <DislikedIcon fontSize="large" color="action" /> }
          { !disliked && <DislikeIcon fontSize="large" color="action" /> }
        </IconButton>
      </Box>
    </Box>

    <Box py={1}>
      <Text variant="subtitle2" fontWeight="bold">{ product.name }</Text>
    </Box>

    <Box display="flex" flexWrap="wrap" gap={1}>
      { product.tags.filter((tag) => tag.type === 'dietary').map((tag) => <Chip key={tag.id} label={tag.name} size="small" sx={{ cursor: 'pointer' }} /> )}
    </Box>

  </Box>
}

export default ProductCard