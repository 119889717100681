import React from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Box,
  Chip,
} from 'components'
import { useContext } from '@market/hooks/useFilteredAccountOrders'
// import { LocationFilter } from './LocationFilter'
import { FamilyFilter } from './FamilyFilter'
import { DateRangeFilter } from './DateRangeFilter'
import { OtherFilters } from './OtherFilters'

export const DateSelector: React.FC = () => {
  const navigateTo = useNavigate()
  const { filters: { availableDates, appliedFilters, setAndApplyFilters} } = useContext()

  return <Box display="flex" alignItems="center" gap={2}>
    { availableDates.sort((a, b) => a.toMillis() - b.toMillis() ).filter((date) => date >= appliedFilters.startDate && date <= appliedFilters.endDate).map((date) => {
      const selected = date.toISO() === appliedFilters.filteredDate?.toISO()

      return <Chip
        key={date.toISO()}
        label={date.toFormat('ccc, LLL d')}
        sx={(theme) => ({
          fontSize: '1rem',
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
          backgroundColor: theme.palette.background.default,
          borderBottomWidth: '4px',
          borderBottomStyle: 'solid',
          borderBottomColor: selected ? theme.palette.plain.main : theme.palette.background.default,
          '&:hover': {
            backgroundColor: theme.palette.background.default,
            [theme.breakpoints.up('lg')]: {
              borderBottomColor: theme.palette.plain.main,
            },
          },
        })}
        onClick={() => {
          if (selected) {
            setAndApplyFilters({ filteredDate: undefined })
            navigateTo('/orders', { state: { filteredDate: undefined } })
          } else {
            setAndApplyFilters({ filteredDate: date })
            navigateTo(`/orders`, { state: { filteredDate: date.toFormat('yyyy-LL-dd') } })
          }
        }}
      />
    }) }
  </Box>
}

export const OrdersFilters: React.FC = () => {
  return <Box>
    <Box display="flex" alignItems="center" gap={2} mb={2}>
      <FamilyFilter />
      <DateRangeFilter />
      <OtherFilters />
    </Box>

    <Box display={{ xs: 'none', md: 'block' }}>
      <DateSelector />
    </Box>
   </Box>
}

export default OrdersFilters
