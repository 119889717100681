import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { DateTime } from 'luxon'
import { Box, EmptyState, PageHeader, PageContent, Text } from 'components'
import { Spinner } from 'components/Loading'
import { Orders as OrdersIcon } from 'icons'
import { Account, Order, SchoolLocation } from '@market/graphql/schema/graphql'
import { ContextProvider, useFilteredAccountOrders, useContext } from '@market/hooks/useFilteredAccountOrders'
import { OrdersFilters } from './OrdersFilters'
import { OrderSummary } from '@market/components'

type RouteProps = {
  groupedAccountOrders: { [x: string]: Order[] }
}

const OrdersDateRoute: React.FC<RouteProps> = ({ groupedAccountOrders }) => {
  const { state } = useLocation()
  const { filters: { setAndApplyFilters, filters: { timeZone } } } = useContext()

  useEffect(() => {
    setAndApplyFilters({ filteredDate: !!state?.filteredDate ? DateTime.fromISO(state.filteredDate).setZone(timeZone) : undefined })
  }, [ state, timeZone, setAndApplyFilters ])

  if (!!state?.filteredDate) {
    if (groupedAccountOrders[state.filteredDate]?.length > 0) {
      return <>{ groupedAccountOrders[state.filteredDate].map((order) => <OrderSummary key={order.id} order={order} />) }</>
    } else {
      return <EmptyState icon={OrdersIcon} message="No orders to display." />
    }
  } else {
    if (Object.values(groupedAccountOrders).flatMap(gmp => gmp).length === 0) {
      return <EmptyState icon={OrdersIcon} message="No orders to display." />
    } else {
      return <>
        { Object.keys(groupedAccountOrders).map((groupDate) => {
          return groupedAccountOrders[groupDate].length === 0 ? null : <Box key={groupDate}>
            {/* <Text variant="h5" mb={2} px={{ xs: 1, sm: 2, md: 0}} sx={(theme) => ({ borderBottomWidth: '1px', borderBottomStyle: 'solid', borderBottomColor: theme.palette.divider })}>
              { DateTime.fromISO(groupDate).toFormat('ccc, LLL d') }
            </Text> */}

            { groupedAccountOrders[groupDate].map((order) => <OrderSummary key={order.id} order={order} />) }
          </Box>
        }) }
      </>
    }
  }
}

export const Orders: React.FC<{ account: Account, locations: SchoolLocation[] }> = ({ account, locations }) => {
  const accountOrderFilters = useFilteredAccountOrders(account, locations)
  const { loading, data: { filteredAccountOrders, groupedAccountOrders } } = accountOrderFilters

  return <ContextProvider { ...accountOrderFilters }>
    <PageHeader sx={(theme) => ({
      [theme.breakpoints.up('md')]: {
        paddingBottom: 0
      }
    })}>
      <Box display={{ xs: 'none', md: 'block' }}>
        <Text variant="h5">Orders</Text>
      </Box>

      <Box mt={1}>
        <OrdersFilters />
      </Box>
    </PageHeader>

    <PageContent sx={{ px: { xs: 0, md: 3 } }}>
      { loading && filteredAccountOrders.length === 0 && <Spinner message="Loading Orders..." /> }
      { (!loading || filteredAccountOrders.length > 0) && <OrdersDateRoute groupedAccountOrders={groupedAccountOrders} /> }
    </PageContent>
  </ContextProvider>
}

export default Orders
