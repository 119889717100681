import React from 'react'
import { styled } from '@mui/material'
import { Box, BoxProps } from 'components/Box'
import { CardWrapper } from './CardWrapper'

const StyledCardGrid = styled(Box)({
  display: 'flex',
  flexWrap: 'wrap',
  gap: 0,
})

export const CardGrid: React.FC<BoxProps> = ({ children, ...props }) => {
  return <StyledCardGrid {...props} className="OrdoCardGrid-root">
    { React.Children.map(children, (child) => <CardWrapper>{ child }</CardWrapper>) }
  </StyledCardGrid>
}

export default CardGrid
