import { useMemo } from 'react'
import { DateTime } from 'luxon'
import { QueryHookOptions } from '@apollo/client'
import { accountCartsQuery } from '@market/graphql/queries'
import type { Cart, AccountCartsQueryQueryVariables } from '@market/graphql/schema/graphql'
import { useMarketQuery } from './useMarketQuery'

type AccountCartsArgs = AccountCartsQueryQueryVariables & QueryHookOptions

export const useAccountCarts = ({ accountId, pollInterval }: AccountCartsArgs) => {
  const queryResult = useMarketQuery<{ accountCarts: Cart[] }, AccountCartsQueryQueryVariables>(
    accountCartsQuery,
    {
      variables: { accountId },
      pollInterval,
    }
  )
  
  const payload = useMemo(() => ({
    ...queryResult,
    loadedAt: DateTime.now(),
    data: {
      accountCarts: [],
      ...queryResult.data,
    }
  }), [queryResult])

  return payload
}

export default useAccountCarts
