import type { CreateAccountUserInput, CreateAccountUserPayload } from '@market/graphql/schema/graphql'
import { accountCartsQuery } from '@market/graphql/queries'
import { createAccountUserMutation } from '@market/graphql/mutations'
import { MutationTuple, useMarketMutation } from './useMarketMutation'

export type CreateAccountUserFn = ReturnType<typeof useCreateAccountUser>[0]
export type CreateAccountUserData = { createAccountUser: CreateAccountUserPayload }

export const useCreateAccountUser: (variables?: CreateAccountUserInput) => MutationTuple<CreateAccountUserData, CreateAccountUserInput> = (variables)  => {
  const [ createAccountUserFn, { data, ...result } ] = useMarketMutation<CreateAccountUserData, CreateAccountUserInput>(
    createAccountUserMutation,
    {
      variables,
      // notifyOnNetworkStatusChange: true,
      refetchQueries: [accountCartsQuery],
    }
  )

  return [ createAccountUserFn, { ...result, data } ]
}

export default useCreateAccountUser
