import { useMemo } from 'react'
import { QueryHookOptions } from '@apollo/client'
import type { Product, AvailableProductsQueryQueryVariables } from '@market/graphql/schema/graphql'
import { availableProductsQuery } from '@market/graphql/queries'
import { useMarketQuery } from './useMarketQuery'

type AvailableProductsArgs = AvailableProductsQueryQueryVariables & QueryHookOptions

export const useAvailableProducts = ({ locationId, pollInterval }: AvailableProductsArgs) => {
  const queryResult = useMarketQuery<{ availableProducts: Product[] }, AvailableProductsQueryQueryVariables>(
    availableProductsQuery,
    {
      variables: { locationId },
      pollInterval,
    }
  )

  const payload = useMemo(() => ({
    ...queryResult,
    data: {
      availableProducts: [],
      ...queryResult.data,
    }
  }), [queryResult])

  return payload
}

export default useAvailableProducts
