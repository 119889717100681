import type { AddProductReactionInput, AddProductReactionPayload } from '@market/graphql/schema/graphql'
import { addProductReactionMutation } from '@market/graphql/mutations'
import { MutationTuple, useMarketMutation } from './useMarketMutation'

export type AddProductReactionFn = ReturnType<typeof useAddProductReaction>[0]
export type AddProductReactionData = { addProductReaction: AddProductReactionPayload }

export const useAddProductReaction: (variables?: AddProductReactionInput) => MutationTuple<{ addProductReaction: AddProductReactionPayload }, AddProductReactionInput> = (variables)  => {
  const [ addProductReactionFn, { data, ...result } ] = useMarketMutation<{ addProductReaction: AddProductReactionPayload }, AddProductReactionInput>(
    addProductReactionMutation,
    {
      variables,
      // notifyOnNetworkStatusChange: true,
    }
  )

  return [ addProductReactionFn, { ...result, data } ]
}

export default useAddProductReaction
