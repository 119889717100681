import React from 'react'
import { Box } from 'components'
import { useViewport } from 'hooks'
// import { Stepper, MobileStepper } from './Stepper'
import type { StepComponents, Step } from '@market/hooks/useRegistrationWizard'
import { useContext } from '@market/hooks/useRegistrationWizard'
import { SelectCampusStep } from './SelectCampusStep'
import { SignupStep } from './SignupStep'

export const stepComponents: StepComponents = {
  campus: SelectCampusStep,
  signup: SignupStep,
}

export interface StepProps {
  step: Step
}

export const DesktopStep: React.FC = () => {
  const wizard = useContext()
  const StepComponent = stepComponents[wizard.step]

  return <Box height="100%" display="flex" alignItems="stretch" className="foobar">
    {/* <Box pl={3} pr={4}>
      <Stepper wizard={wizard} />
    </Box> */}

    <Box pl={0/*4*/} flexGrow={1}>
      { wizard.activeStep?.available() && <StepComponent wizard={wizard} /> }
    </Box>
  </Box>
}

export const MobileStep: React.FC = () => {
  const wizard = useContext()
  const StepComponent = stepComponents[wizard.step]

  return <Box height="100%" position="relative">
    {/* <MobileStepper wizard={wizard} position="top" /> */}

    <Box pt={0/*8*/} width="100%">
      { wizard.activeStep?.available() && <StepComponent wizard={wizard} /> }
    </Box>
  </Box>
}

export const WizardStep: React.FC = () => {
  const { isTablet } = useViewport()

  return isTablet ? <DesktopStep /> : <MobileStep />
}

export default WizardStep
