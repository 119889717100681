import React from 'react'
import { Box, BoxProps } from 'components/Box'
import { AccountUser } from '@market/graphql/schema/graphql'
import { useFilteredAvailableProducts } from '@market/hooks'
import { ProductGrid } from './ProductGrid'
import { ProductFilters } from './ProductFilters'

export const TasteProfile: React.FC<BoxProps & { accountUser: AccountUser }> = ({ accountUser, ...boxProps }) => {
  const {
    loading, // TODO: Show loading state
    filters,
    data: { filteredProducts }
  } = useFilteredAvailableProducts(accountUser)

  return <Box {...boxProps}>
    <ProductFilters
      loading={loading}
      {...filters}
    />

    <ProductGrid accountUser={accountUser} products={filteredProducts} />
  </Box>
}

export default TasteProfile
