import React from 'react'
import { Page } from 'components'
import { useFilteredFamily } from '@market/hooks'
import {
  Family,
  // FamilyFilters,
} from '@market/compositions/Family'

export const MyFamily: React.FC = () => {
  const { /*account, filters,*/ data: { filteredFamily } } = useFilteredFamily()

  return <Page fullWidth sx={{ padding: { xs: 0, sm: 2 }}}>
    {/* <FamilyFilters
      account={account}
      {...filters}
    /> */}

    <Family family={filteredFamily} />
  </Page>
}

export default MyFamily
