import React from 'react'
import { Image } from 'mui-image'
import { styled } from '@mui/material'
import { Box, BoxProps } from 'components/Box'
import { Text } from 'components/Text'
import { Product } from '@market/graphql/schema/graphql'
import { QuantityChip } from '../QuantityChip'

const ProductQuantityCardBox = styled(Box)(({ theme }) => ({
  borderStyle: 'solid',
  borderColor: theme.palette.divider,
  borderWidth: 0,
  borderBottomWidth: '1px',
  overflow: 'hidden',
  width: '100%',
  maxWidth: '100%',
  paddingTop: 0,
  paddingBottom: 0,
  paddingRight: 0,
  '&:first-child': {
    borderTopWidth: '1px',
  },
  [theme.breakpoints.up('md')]: {
    borderRadius: '10px',
    borderWidth: '1px',
    width: '400px',
    maxWidth: '400px',
    paddingTop: 0,
    paddingBottom: 0,
    paddingRight: 0,
  },
  [theme.breakpoints.up('xl')]: {
    width: '500px',
    maxWidth: '500px',
  },
}))

export const ProductQuantityCard: React.FC<Pick<BoxProps, 'sx' | 'onClick'> & { product: Product, quantity: number, reverse?: boolean }> = ({ product, quantity, reverse, sx, onClick }) => {
  return <ProductQuantityCardBox className="OrdoProductQuantityCard-root" display="flex" flexWrap="nowrap" flexDirection={reverse ? 'row-reverse' : 'row'} gap={2} alignItems="center" sx={sx} onClick={onClick}>
    <Box
      mr={reverse ? undefined : 'auto'}
      ml={reverse ? 'auto' : undefined}
      flexShrink={0}
      width={{ xs: '120px', md: '100px' }}
      height={{ xs: '120px', md: '100px' }}
    >
      <Image
        src={product.featuredImage}
        duration={0}
        width="100%"
        height="100%"
      />
    </Box>

    <Box flexGrow={1}>
      <Text variant="subtitle1" fontWeight="medium">{ product.name }</Text>
    </Box>

    <Box mr={reverse ? 0 : 2} ml={reverse ? 2 : 0}>
      <QuantityChip label={quantity} />
    </Box>
  </ProductQuantityCardBox>
}

export default ProductQuantityCard