import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import {
  Box,
  Button,
  Card,
  CardContent,
  Text
} from 'components'
import { Account, AccountUser } from '@market/graphql/schema/graphql'

export const SubscriptionCard: React.FC<{ account: Account, user: AccountUser }> = ({ user }) => {
  return <Box key={user.id}>
    <Card sx={(theme) => ({
      [theme.breakpoints.up('md')]: {
        width: theme.spacing(96),
      },
      [theme.breakpoints.up('lg')]: {
        width: theme.spacing(96),
      },
    })}>
      <CardContent sx={{ p: 4 }}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box>
            <Text variant="h6">{ user.user.preferredName || user.user.firstName }</Text>
          </Box>

          <Box>
            { (!user.subscription || user.subscription.status === "initialized") && <Button component={RouterLink} to={`/subscriptions/create/${user.id}`} color="info" size="large" fullWidth={false}>Subscribe</Button> }
            { (user.subscription && user.subscription.status !== "initialized") && <Button component={RouterLink} to={`/subscriptions/edit/${user.id}`} size="large" fullWidth={false}>Edit</Button> }
          </Box>
        </Box>
      </CardContent>
    </Card>
  </Box>
}

export default SubscriptionCard
