import { setDefaultPaymentMethodMutation } from '@market/graphql/mutations'
import { MutationTuple, useMarketMutation } from './useMarketMutation'
import type { SetDefaultPaymentMethodInput, SetDefaultPaymentMethodPayload } from '@market/graphql/schema/graphql'

export type SetDefaultPaymentMethodMutationData = { setDefaultPaymentMethod: SetDefaultPaymentMethodPayload }

export const useSetDefaultPaymentMethod: (variables?: SetDefaultPaymentMethodInput) => MutationTuple<SetDefaultPaymentMethodPayload, SetDefaultPaymentMethodInput> = (variables)  => {
  const [ setDefaultPaymentMethodFn, { data, ...result } ] = useMarketMutation<SetDefaultPaymentMethodPayload, SetDefaultPaymentMethodInput>(
    setDefaultPaymentMethodMutation,
    {
      variables,
      // notifyOnNetworkStatusChange: true
    }
  )

  return [ setDefaultPaymentMethodFn, { ...result, data } ]
}

export default useSetDefaultPaymentMethod
