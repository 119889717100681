import { enqueueSnackbar } from 'notistack'
import { z } from 'zod'
import { toFormikValidationSchema } from 'zod-formik-adapter'
import { useMutationForm, MutationFormTuple, CoerceFn, MutationFormConfig, OnSuccessFn } from 'hooks/useMutationForm'
import { UpdateAccountUserInput, AccountUser } from '@market/graphql/schema/graphql'
import { useUpdateAccountUser, UpdateAccountUserData } from './useUpdateAccountUser'

export const zodSchema = z.object({
  id: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  preferredName: z.string().optional(),
  phoneNumber: z.string().optional(),
  role: z.string(),
  rosterId: z.string().optional(),
}).superRefine(({ role, rosterId }, ctx) => {
  if (['STUDENT', 'STAFF'].includes(role) && (!rosterId || rosterId === '')) {
    ctx.addIssue({
      code: 'custom',
      message: 'Please select one',
      path: ['rosterId']
    })
  }
})

export type UpdateAccountUserFormInput = Required<z.infer<typeof zodSchema>>

export const validationSchema = toFormikValidationSchema(zodSchema)

export const useUpdateAccountUserForm = (accountUser: AccountUser, config?: MutationFormConfig<UpdateAccountUserData, UpdateAccountUserInput, UpdateAccountUserFormInput>): MutationFormTuple<UpdateAccountUserData, UpdateAccountUserFormInput> => {
  const coerce: CoerceFn<UpdateAccountUserInput, UpdateAccountUserFormInput> = ({ id, ...values }) => {
    const coerced: UpdateAccountUserInput = { id, accountUser: values }

    return coerced
  }

  const onSuccess: OnSuccessFn<UpdateAccountUserData, UpdateAccountUserFormInput> = (data, helpers) => {
    enqueueSnackbar("User updated", { variant: "success", autoHideDuration: 2000, preventDuplicate: true })
    if (config?.onSuccess) config.onSuccess(data, helpers)
  }

  const [ mutationForm, mutationResult] = useMutationForm<UpdateAccountUserData, UpdateAccountUserInput, UpdateAccountUserFormInput>(useUpdateAccountUser, {
    ...config,
    coerce,
    onSuccess,
    validationSchema,
    initialValues: {
      id: accountUser?.id || '',
      firstName: accountUser?.user?.firstName || '',
      lastName: accountUser?.user?.lastName || '',
      preferredName: accountUser?.user?.preferredName || '',
      phoneNumber: accountUser?.user?.phoneNumber || '',
      role: accountUser?.roles?.filter((role) => ['GUARDIAN', 'STUDENT', 'STAFF'].includes(role))[0] || 'GUARDIAN',
      rosterId: accountUser?.user?.roster?.id || '',
    }
  })

  return [ mutationForm, mutationResult ]
}

export default useUpdateAccountUserForm