import type { RemoveProductReactionInput, RemoveProductReactionPayload } from '@market/graphql/schema/graphql'
import { removeProductReactionMutation } from '@market/graphql/mutations'
import { MutationTuple, useMarketMutation } from './useMarketMutation'

export type RemoveProductReactionFn = ReturnType<typeof useRemoveProductReaction>[0]

export const useRemoveProductReaction: (variables?: RemoveProductReactionInput) => MutationTuple<RemoveProductReactionPayload, RemoveProductReactionInput> = (variables)  => {
  const [ removeProductReactionFn, { data, ...result } ] = useMarketMutation<RemoveProductReactionPayload, RemoveProductReactionInput>(
    removeProductReactionMutation,
    {
      variables,
      // notifyOnNetworkStatusChange: true
    }
  )
  // TODO: update cached user reactions

  return [ removeProductReactionFn, { ...result, data } ]
}

export default useRemoveProductReaction
