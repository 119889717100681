import { gql } from '@market/graphql/schema/gql'

export const cancelOrderMutation = gql(`
  mutation cancelOrderMutation($input: CancelOrderInput! ) {
    cancelOrder(input: $input) {
      order {
        ...OrderFields
      }
      errors {
        code
        message
        path
      }
    }
  }
`)

export default cancelOrderMutation
