import { useMemo } from 'react'
import { QueryHookOptions } from '@apollo/client'
import { accountQuery } from '@market/graphql/queries'
import type { Account, AccountQueryQueryVariables } from '@market/graphql/schema/graphql'
import { useMarketQuery } from './useMarketQuery'

type AccountArgs = AccountQueryQueryVariables & QueryHookOptions

export const useAccount = ({ accountId, pollInterval }: AccountArgs) => {
  const queryResult = useMarketQuery<{ account: Account }, AccountQueryQueryVariables>(
    accountQuery,
    {
      variables: { accountId },
      pollInterval,
    }
  )

  const payload = useMemo(() => ({
    ...queryResult,
    data: {
      account: {},
      ...queryResult.data,
    }
  }), [queryResult])

  return payload
}

export default useAccount
