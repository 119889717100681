import React, { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { Page } from 'components'
import { useAccountContext } from '@market/hooks/useCurrentAccount'
import { SubscriptionWizardRouter } from '@market/compositions/Subscriptions'

export const CreateSubscription: React.FC = () => {
  const { accountUserId } = useParams()
  const { account } = useAccountContext()
  const accountUser = useMemo(() => account.accountUsers?.find((acctUser) => acctUser.id === accountUserId), [account, accountUserId])

  return <Page fullWidth>
    <SubscriptionWizardRouter accountUser={accountUser} />
  </Page>
}

export default CreateSubscription
